define('swap-system/routes/rma-approve', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/log-loader', 'swap-system/mixins/routes/confirmation-modal', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _logLoader, _confirmationModal, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _logLoader.default, _confirmationModal.default, {
		requiredGroup: _userGroups.default.rmaAdmin,
		merchant: Ember.inject.service(),
		titleToken: function titleToken(model) {
			return 'RMA Approval #' + model.rmaApproval.id;
		},
		model: function model(params) {
			var _this = this;

			return this.store.findRecord('rmaApproval', params.id).then(function (rmaApproval) {
				var merchant = _this.get('merchant');
				merchant.set('id', rmaApproval.get('mid'));
				return Ember.RSVP.hash({
					rmaApproval: rmaApproval,
					products: _this.store.findAll('product'),
					rmaStatus: _this.store.findRecord('rmaStatus', 1),
					rmaStatuses: _this.store.findAll('rmaStatus'),
					rmaApprovalStatuses: _this.store.findAll('rmaApprovalStatus'),
					rmaReasons: _this.store.findAll('rmaReason'),
					brands: _this.store.findAll('brand'),
					orderType: _this.store.findAll('orderType'),
					processorTypes: _this.store.findAll('processorType'),
					logs: null
				}).then(function (resp) {
					if (resp.rmaApproval.get('rmaApprovalStatus.id') != 1) {
						return resp;
					}
					return _this.store.findRecord('brand', resp.rmaApproval.get('brand.id')).then(function () {
						return resp;
					});
				});
			});
		}
	});
});