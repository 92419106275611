define('swap-system/routes/rma', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/log-loader', 'swap-system/mixins/routes/confirmation-modal', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _logLoader, _confirmationModal, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _logLoader.default, _confirmationModal.default, {
		requiredGroup: _userGroups.default.rmaUser,
		merchant: Ember.inject.service(),
		titleToken: function titleToken(model) {
			return 'RMA #' + model.rma.id;
		},
		model: function model(params) {
			var _this = this;

			return Ember.RSVP.hash({
				rma: this.store.findRecord('rma', params.id),
				rmaStatuses: this.store.findAll('rmaStatus'),
				rmaApprovalStatuses: this.store.findAll('rmaApprovalStatus'),
				swapStatuses: this.store.findAll('swapStatus'),
				swapApprovalStatuses: this.store.findAll('swapApprovalStatus'),
				brands: this.store.findAll('brand'),
				processorTypes: this.store.findAll('processorType'),
				logs: null
			}).then(function (resp) {
				var merchant = _this.get('merchant');
				merchant.set('id', resp.rma.get('mid'));

				return resp;
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('comment', '');
		}
	});
});