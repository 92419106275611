define('swap-system/mixins/controllers/due-date', ['exports', 'moment', 'swap-system/config/environment'], function (exports, _moment, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		selectedItem: null,
		actions: {
			openPicker: function openPicker(item) {
				this.set('selectedItem', item);
			},
			closePicker: function closePicker() {
				this.set('selectedItem', null);
			},
			setDueDate: function setDueDate(date) {
				if (this.get('selectedItem')) {
					var dueDate = (0, _moment.default)(date).tz(_environment.default.locale).set(_environment.default.rmaDueDate);

					if (this.get('selectedItem.dueDate')) {
						// rma fee
						this.get('selectedItem').set('dueDate', dueDate.toDate());
					} else {
						// rma detail item
						this.get('selectedItem').set('dueOn', dueDate.toDate());
					}
				}
			}
		}
	});
});