define('swap-system/components/swap-info-box', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		modelName: null,
		status: null,
		swapId: null
	});
});