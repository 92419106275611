define('swap-system/models/default-shipping-address', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany;
	exports.default = Model.extend({
		addressName: attr('string'),
		alteredShippingAddress: hasMany('alteredShippingAddress')
	});
});