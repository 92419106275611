define('swap-system/routes/entry', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, {
		user: Ember.inject.service('user'),
		session: Ember.inject.service('session'),
		beforeModel: function beforeModel() {
			if (!this.get('session.isAuthenticated')) {
				return this.transitionTo('login');
			}

			if (this.get('user').belongsTo(_userGroups.default.swapUser)) {
				this.transitionTo('swaps');
			} else {
				this.transitionTo('rmas');
			}
		}
	});
});