define('swap-system/models/rma', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/mixins/models/commit-details'], function (exports, _emberData, _emberCpValidations, _commitDetails) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		mid: (0, _emberCpValidations.validator)('presence', { presence: true }),
		dba: (0, _emberCpValidations.validator)('presence', { presence: true }),
		file: (0, _emberCpValidations.validator)('presence', { presence: true }),
		rmaDetails: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'Select at least 1 equipment'
		})],
		rmaComments: (0, _emberCpValidations.validator)('has-many'),
		rmaReason: (0, _emberCpValidations.validator)('presence', { presence: true }),
		rmaStatus: (0, _emberCpValidations.validator)('presence', { presence: true })
	});

	exports.default = Model.extend(validations, _commitDetails.default, {
		mid: attr('string'),
		isDue: attr('boolean'),
		dueDate: attr('date'),
		dba: attr('string'),
		returnTrackingNumber: attr('string'),
		createdAt: attr('date'),
		createdBy: attr('string'),
		cancelledBy: attr('string'),
		cancelledAt: attr('date'),
		submittedAt: attr('utc-format'),
		submittedBy: attr('string'),
		alertMerchant: attr('boolean'),
		alertVAR: attr('boolean'),
		file: belongsTo('merchant'),
		rmaComments: hasMany('rmaComment'),
		rmaDetails: hasMany('rmaDetail'),
		swap: belongsTo('swap'),
		orderType: belongsTo('orderType'),
		processorType: belongsTo('processorType'),
		rmaReason: belongsTo('rmaReason'),
		rmaApproval: belongsTo('rmaApproval'),
		rmaStatus: belongsTo('rmaStatus'),
		rmaFees: hasMany('rmaFee'),
		brand: belongsTo('brand'),

		total: Ember.computed('rmaDetails.@each.billAmount', function () {
			var total = 0;
			this.get('rmaDetails').forEach(function (x) {
				if (!x.get('isReturned') && !x.get('isBilled') && x.get('billingEnabled')) {
					total += parseInt(x.get('billAmount')) || 0;
				}
			});
			return total;
		})
	});
});