define('swap-system/serializers/log', ['exports', 'swap-system/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		normalizeResponse: function normalizeResponse() {
			var json = this._super.apply(this, arguments);

			json.data.forEach(function (log) {
				if (log.attributes.newValue === 'Invalid Date') {
					log.attributes.newValue = null;
				}
			});

			return json;
		}
	});
});