define('swap-system/validations/swap-parameters-validations', ['exports', 'ember-cp-validations', 'swap-system/enums/trackingApis'], function (exports, _emberCpValidations, _trackingApis) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var trackingRegex = Object.values(_trackingApis.default).reduce(function (acc, trackingApi) {
		return trackingApi.patterns.reduce(function (resultedAcc, pattern) {
			return RegExp(resultedAcc.source + '|' + pattern.source);
		}, acc);
	}, /^$/);

	exports.default = {
		trackingNumber: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			regex: trackingRegex,
			message: 'Tracking number must be a valid UPS tracking number'
		})
	};
});