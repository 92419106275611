define('swap-system/services/user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		userData: null,
		userName: Ember.computed('userData.{firstName,lastName}', function () {
			return this.get('userData.firstName') + ' ' + this.get('userData.lastName');
		}),
		fetchUserData: function fetchUserData(token) {
			var _this = this;

			return this.get('ajax').request('/api/v1/users/self', {
				headers: {
					'auth-token': token
				}
			}).then(function (_ref) {
				var user = _ref.user;

				_this.set('userData', user);
			});
		},
		belongsTo: function belongsTo(group) {
			return this.get('userData.groups').includes(group);
		}
	});
});