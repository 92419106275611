define('swap-system/models/log', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend({
		user: attr('string'),
		column: attr('string'),
		table: attr('string'),
		primaryKey: attr('string'),
		oldValue: attr('string'),
		newValue: attr('string'),
		createdAt: attr('date'),
		log: attr('string')
	});
});