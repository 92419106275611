define('swap-system/mixins/routes/log-loader', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		afterModel: function afterModel(model) {
			var ticketModel = this.controllerFor(this.get('routeName')).get('ticketModel');
			var logParams = this.getLogParams(model[ticketModel]);
			this.loadLogs(model, logParams);
		},
		getLogParams: function getLogParams(model) {
			var logParams = [];
			var swap = this.get('routeName') == 'swap' ? model : model.get('swap.content') ? model.get('swap') : null;
			var rma = null;

			if (this.get('routeName') == 'rma') {
				rma = model;
			} else if (this.get('routeName') == 'approve') {
				rma = model.get('swap.rma.content') ? model.get('swap.rma') : null;
			} else if (this.get('routeName') == 'swap') {
				rma = model.get('rma.content') ? model.get('rma') : null;
			}

			if (swap) {
				logParams.push({
					swaps: swap.get('id'),
					swapDetails: swap.get('swapDetails').mapBy('id'),
					swapComments: swap.get('swapComments').mapBy('id'),
					swapApprovals: swap.get('swapApprovalId.id')
				});
			}

			if (rma) {
				logParams.push({
					rmas: rma.get('id'),
					rmaDetails: rma.get('rmaDetails').mapBy('id'),
					rmaComments: rma.get('rmaComments').mapBy('id'),
					rmaApprovals: rma.get('rmaApproval.id'),
					rmaFees: rma.get('rmaFees').mapBy('id')
				});
			}

			return logParams;
		},
		loadLogs: function loadLogs(model, logParams) {
			var _this = this;

			var logs = [];
			var loadLogs = [];

			logParams.forEach(function (logParam) {
				loadLogs.push(_this.store.query('log', logParam));
			});

			Ember.RSVP.all(loadLogs).then(function (resp) {
				resp.forEach(function (logRecords) {
					logRecords.forEach(function (log) {
						logs.push(log);
					});
				});

				Ember.set(model, 'logs', logs.sort(function (a, b) {
					return _moment.default.utc(b.get('createdAt')).diff(_moment.default.utc(a.get('createdAt')));
				}));
			});
		},

		actions: {
			loadLogs: function loadLogs() {
				var model = this.modelFor(this.routeName);
				var ticketModel = this.controllerFor(this.get('routeName')).get('ticketModel');
				var logParams = this.getLogParams(model[ticketModel]);
				this.loadLogs(model, logParams);
			}
		}
	});
});