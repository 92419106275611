define('swap-system/serializers/rma', ['exports', 'ember-data', 'swap-system/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			rmaDetails: { embedded: 'always' },
			rmaFees: { embedded: 'always' },
			rmaComments: { embedded: 'always' },
			rmaApproval: { serialize: 'id', deserialize: 'records' },
			rmaReason: { serialize: 'id', deserialize: 'records' },
			file: { serialize: 'id', deserialize: 'id' },
			rmaStatus: { serialize: 'id', deserialize: 'records' },
			swap: { embedded: 'always' },
			brand: { serialize: 'id', deserialize: 'records' },
			orderType: { serialize: 'id', deserialize: 'records' },
			processorType: { serialize: 'id', deserialize: 'records' }
		}
	});
});