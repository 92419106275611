define('swap-system/helpers/substitution-product-selector', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.substitutionProductSelector = substitutionProductSelector;


	/**
  * [substitutionProductSelector description]
  * @method  substitutionProductSelector
  * @param   {[type]}        params params of power-select
  * @param   {Array}         substitutionProducts   Array of available substitution products
  * @param   {Array}         substitutionSubproducts   Array of available substitution subproducts
  * @param   {Array}         products   Array of existing products
  * @param   {Object}        product   Products, that need substitution variants
  * @returns {Array}         returns array of substitution variants for power-select element
  */
	function substitutionProductSelector(params, _ref) {
		var substitutionProducts = _ref.substitutionProducts,
		    substitutionSubproducts = _ref.substitutionSubproducts,
		    products = _ref.products,
		    product = _ref.product;

		var productId = product.get('id');

		return substitutionProducts.filterBy('originalProduct.id', productId).reduce(function (availableSubstitutionVariants, substitutionProduct) {
			var substitutionProductsList = products.filterBy('id', substitutionProduct.get('substitutionProduct.id'));
			var generatedSubstitutionVariants = [];

			substitutionProductsList.forEach(function (substitutionProductsListItem) {
				var substitutionProductObjectId = substitutionProduct.get('id');

				var filteredSubstitutionSubproducts = substitutionSubproducts.filterBy('substitutionProductId', substitutionProductObjectId);

				var substitutionVariantValues = Ember.getProperties(substitutionProductsListItem, 'productName', 'modelNo', 'id');
				var substitutionVariant = {
					productName: substitutionVariantValues.productName,
					modelNo: substitutionVariantValues.modelNo,
					substitutionProductId: substitutionVariantValues.id,
					substitutionSubproductIds: []
				};

				filteredSubstitutionSubproducts.forEach(function (substitutionSubproductModel) {
					var substitutionSubproduct = substitutionSubproductModel.get('product');
					var substitutionSubproductValues = Ember.getProperties(substitutionSubproduct, 'productName', 'modelNo', 'id');

					substitutionVariant.productName = substitutionVariant.productName + ' + ' + substitutionSubproductValues.productName;
					substitutionVariant.modelNo = substitutionVariant.modelNo + ' + ' + substitutionSubproductValues.modelNo;
					substitutionVariant.substitutionSubproductIds.push(substitutionSubproductValues.id);
				});

				generatedSubstitutionVariants.push(substitutionVariant);
			});

			availableSubstitutionVariants.push.apply(availableSubstitutionVariants, generatedSubstitutionVariants);

			return availableSubstitutionVariants;
		}, [{
			productName: product.get('productName'),
			modelNo: product.get('modelNo'),
			substitutionProductId: product.get('id'),
			substitutionSubproductIds: []
		}]);
	}

	exports.default = Ember.Helper.helper(substitutionProductSelector);
});