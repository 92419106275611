define('swap-system/helpers/token-data-property', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Helper = Ember.Helper,
	    inject = Ember.inject,
	    observer = Ember.observer,
	    assert = Ember.assert;
	exports.default = Helper.extend({
		session: inject.service(),
		user: inject.service(),
		onNewUser: observer('session.session.content.authenticated', function () {
			this.recompute();
		}),
		compute: function compute(params) {
			assert('The token-data-property helper only accepts 1 parameter. No more, no less. For now at least.', params.length === 1);

			var user = this.get('user.userData');

			if (user) {
				user.name = user.firstName + ' ' + user.lastName;
				return user[params[0]];
			}

			return user;
		}
	});
});