define('swap-system/controllers/admin-panel-product-list', ['exports', 'swap-system/mixins/controllers/ticket-list', 'swap-system/mixins/controllers/server-table-common-properties', 'swap-system/mixins/controllers/hide-show'], function (exports, _ticketList, _serverTableCommonProperties, _hideShow) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_ticketList.default, _serverTableCommonProperties.default, _hideShow.default, {
		routeName: 'adminPanelProduct',
		useFilteringByColumns: true,
		focusGlobalFilter: false,
		sendDisplayDataChangedAction: false
	});
});