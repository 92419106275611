define('swap-system/mixins/controllers/track-item', ['exports', 'swap-system/enums/trackingApis'], function (exports, _trackingApis) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		actions: {
			trackPackage: function trackPackage(item) {
				var trackingApi = Object.values(_trackingApis.default).find(function (selectedTrackingApi) {
					return selectedTrackingApi.patterns.find(function (pattern) {
						return RegExp(pattern).test(item);
					});
				});

				window.open('' + trackingApi.url.replace('{{trackingNumber}}', item), '_blank');
			}
		}
	});
});