define('swap-system/mixins/models/swap/set-notifications', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		setNotifications: function setNotifications(notifications, model) {
			if (model.get('flaggedNotificationError')) {
				notifications.warning('The flagged swap notification wasn\'t sent!');
				model.set('flaggedNotificationError', null);
			}

			if (model.get('orderShippedNotificationError')) {
				notifications.warning('The shipped email notification wasn\'t sent!');
				model.set('orderShippedNotificationError', null);
			}
		}
	});
});