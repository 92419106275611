define('swap-system/mixins/routes/confirmation-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		actions: {
			willTransition: function willTransition(transition) {
				var model = this.currentModel;
				var controller = this.get('controller');
				if (controller) {
					var modelName = controller.get('ticketModel');
					var isDirty = model[modelName].get('hasDirtyAttributes');
					var changedAttributes = model[modelName].changedAttributes();
					var hasDirtyDetails = model[modelName].get(modelName + 'Details').filterBy('hasDirtyAttributes').length > 0;

					delete changedAttributes.merchantAddresses; // ignore merchantAddresses readOnly property

					var isChanged = isDirty && Object.keys(changedAttributes).length > 0;
					var isAnyDetailChanged = hasDirtyDetails && model[modelName].currentState.stateName == 'root.loaded.updated.uncommitted';

					if (isChanged || isAnyDetailChanged) {
						transition.abort();
						controller.send('openConfirmationModal', transition);
					}
				}
			}
		}
	});
});