define('swap-system/mixins/controllers/set-shipping-method', ['exports', 'swap-system/enums/groundUSAZips', 'swap-system/enums/shippingMethods'], function (exports, _groundUSAZips, _shippingMethods) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var groundShippingMethod = 'Ground';

	exports.default = Ember.Mixin.create({
		chosenShippingMethod: '',
		selectedShippingMethod: Ember.computed('chosenShippingMethod', function () {
			if (!this.get('chosenShippingMethod')) {
				var addresses = this.get('model.merchant.addresses');
				var defaultAddress = this.get('model.merchant.defaultAddress');
				var shippingState = this.get('model.swapApproval.shippingState');
				var shippingZip = this.get('model.swapApproval.shippingZip');
				var shippingMethod = this.get('model.swapApproval.shippingMethod');

				var chosenShippingMethod = this.getShippingMethod(addresses, defaultAddress, shippingState, shippingZip, shippingMethod);
				this.setProperties({
					chosenShippingMethod: chosenShippingMethod
				});

				return chosenShippingMethod;
			}

			return this.get('model.swapApproval.shippingMethod');
		}),
		swapShippingMethodObserver: Ember.observer('model.swapApproval.shippingMethod', function () {
			var _this = this;

			this.setProperties({
				chosenShippingMethod: _shippingMethods.default.find(function (method) {
					return method === _this.get('model.swapApproval.shippingMethod');
				}) || groundShippingMethod
			});
		}),
		merchantIdObserver: Ember.observer('model.swapApproval.shippingState', function () {
			var addresses = this.get('model.merchant.addresses');
			var defaultAddress = this.get('model.merchant.defaultAddress');
			var shippingState = this.get('model.swapApproval.shippingState');
			var shippingZip = this.get('model.swapApproval.shippingZip');
			var shippingMethod = this.get('model.swapApproval.shippingMethod');

			this.setProperties({
				chosenShippingMethod: this.getShippingMethod(addresses, defaultAddress, shippingState, shippingZip, shippingMethod)
			});
		}),
		getShippingMethod: function getShippingMethod(addresses, defaultAddress, shippingState, shippingZip, shippingMethod) {
			var address = addresses.find(function (_ref) {
				var addressType = _ref.addressType;
				return addressType === defaultAddress;
			}) || {};
			var state = shippingState || address.state || '';
			var zip = shippingZip || address.zip || '';
			var defaultShippingMethod = _shippingMethods.default.find(function (method) {
				return method === shippingMethod;
			}) || groundShippingMethod;

			if (!state || !zip) {
				return defaultShippingMethod;
			}

			var includesGroundStateAndZip = (_groundUSAZips.default[state] || []).includes(zip);

			return includesGroundStateAndZip ? groundShippingMethod : defaultShippingMethod;
		}
	});
});