define('swap-system/helpers/gt-one', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			return params[0] > 1;
		}
	});
});