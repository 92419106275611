define('swap-system/mixins/routes/ticket-search-route-mixin', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.setProperties({
				search: ''
			});
		}
	});
});