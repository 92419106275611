define('swap-system/mixins/controllers/server-table-common-properties', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		debounceDataLoadTime: 750,
		sticky: true,
		timeZone: 'UTC',
		multipleColumnsSorting: false,
		doFilteringByHiddenColumns: false,
		useNumericPagination: true,
		showTableSummary: false
	});
});