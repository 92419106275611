define('swap-system/services/create-addresses-observer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = createObserver;


	var NOT_UPPERCASE_ADDRESSES = ['other', 'legal'];

	function createObserver(modelName) {

		return Ember.observer('model.' + modelName + '.merchantAddresses.@each', 'model.' + modelName + '.shippingAddress1', 'model.' + modelName + '.shippingAddress2', 'model.' + modelName + '.shippingCity', 'model.' + modelName + '.shippingState', 'model.' + modelName + '.shippingZip', function () {
			var merchantId = this.get('model.' + modelName + '.mid');

			if (!merchantId) {
				return;
			}

			var address1 = this.get('model.' + modelName + '.shippingAddress1');
			var address2 = this.get('model.' + modelName + '.shippingAddress2');
			var city = this.get('model.' + modelName + '.shippingCity');
			var state = this.get('model.' + modelName + '.shippingState');
			var zip = this.get('model.' + modelName + '.shippingZip');
			var merchantAddresses = this.get('model.' + modelName + '.merchantAddresses');

			if (merchantAddresses) {
				var enteredAddress = {
					address1: address1,
					address2: address2,
					city: city,
					state: state,
					zip: zip
				};

				var sameAddress = this.checkSameAddresses(merchantAddresses, enteredAddress);

				if (sameAddress) {
					if (!this.get('chosenAddress')) {
						var chosenAddress = void 0;

						chosenAddress = NOT_UPPERCASE_ADDRESSES.includes(sameAddress.addressType) ? '' + sameAddress.addressType.charAt(0).toUpperCase() + sameAddress.addressType.substr(1) + ' Address' : sameAddress.addressType.toUpperCase() + ' Address';

						this.set('chosenAddress', chosenAddress);
					}

					this.set('customAddressMessage', '');
				} else {
					this.set('customAddressMessage', 'Address was modified manually and doesn’t equal to addresses saved in Titan');
					this.set('chosenAddress', null);
				}
			}
		});
	}
});