define('swap-system/components/select-available-substitution-product', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		manualSerial: null,
		groupedProducts: Ember.computed('products', function () {
			var allProducts = this.get('products');

			return [{
				groupName: 'All',
				options: allProducts.toArray()
			}];
		}),
		actions: {
			selectSubstitutionProduct: function selectSubstitutionProduct(substitutionProduct, selectedProduct) {
				var _selectedProduct$getP = selectedProduct.getProperties(['modelNo', 'productName']),
				    modelNo = _selectedProduct$getP.modelNo,
				    productName = _selectedProduct$getP.productName;

				substitutionProduct.setProperties({
					productName: productName,
					modelNo: modelNo,
					product: selectedProduct
				});
			}
		}
	});
});