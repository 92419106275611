define('swap-system/routes/admin-panel-product', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/clear-store-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _clearStoreMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _clearStoreMixin.default, {
		requiredGroup: _userGroups.default.swapAdmin,
		model: function model(params) {
			return Ember.RSVP.hash({
				adminPanelProduct: this.store.findRecord('adminPanelProduct', params.id, { reload: true }),
				products: this.store.findAll('product')
			});
		},

		deactivate: function deactivate() {
			this.controller.reloadAdminPanelProduct();
		}
	});
});