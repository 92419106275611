define('swap-system/routes/application', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		title: function title(tokens) {
			tokens = Ember.makeArray(tokens);
			return tokens ? tokens.join(' | ') : 'Swap/RMA System';
		},
		deactivate: function deactivate() {
			this._super.apply(this, arguments);
			this.controller.destroy();
		},

		actions: {
			didTransition: function didTransition() {
				this.controller.refreshCounters();
			},
			modelPropertyToggle: function modelPropertyToggle(model, property) {
				if (model !== undefined && property !== undefined && (typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object' && typeof property === 'string') {
					model.toggleProperty(property);
				}
			}
		}
	});
});