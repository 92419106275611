define('swap-system/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		normalize: function normalize(modelClass, json) {
			modelClass.eachRelationship(function (name, relationship) {
				if (relationship.kind === 'belongsTo') {
					if (json[name + 'Id'] && !json[name]) {
						json[name] = json[name + 'Id'];
					}
					delete json[name + 'Id'];
				}
			});
			return this._super(modelClass, json);
		},
		serialize: function serialize(snapshot) {
			var json = this._super.apply(this, arguments);

			snapshot.eachRelationship(function (name, relationship) {
				if (relationship.kind === 'hasMany' && typeof json[name] === 'string') {
					json[name + 'Ids'] = json[name];
					delete json[name];
				}
				if (relationship.kind === 'belongsTo' && typeof json[name] === 'string') {
					json[name + 'Id'] = json[name];
					delete json[name];
				}
			});

			return json;
		}
	});
});