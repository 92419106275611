define('swap-system/helpers/sort-array', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			var array = params[0];
			var field = params[1];

			if (!array.length) {
				return '';
			}

			var fieldValueArray = [];

			array.forEach(function (item) {
				fieldValueArray.push(item.get(field));
			});

			fieldValueArray = fieldValueArray.sort(function (a, b) {
				return a < b ? -1 : a > b ? 1 : 0;
			});

			return fieldValueArray;
		}
	});
});