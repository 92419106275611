define('swap-system/helpers/time-zone', ['exports', 'ember-common/helpers/time-zone'], function (exports, _timeZone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timeZone.default;
    }
  });
});