define('swap-system/enums/statusColors', ['exports', 'swap-system/enums/rmaStatuses', 'swap-system/enums/rmaApprovalStatuses', 'swap-system/enums/swapStatuses', 'swap-system/enums/boardingStatuses', 'swap-system/enums/swapApprovalStatuses'], function (exports, _rmaStatuses, _rmaApprovalStatuses, _swapStatuses, _boardingStatuses, _swapApprovalStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		rma: [{
			id: _rmaStatuses.default.approved,
			color: 'yellow'
		}, {
			id: _rmaStatuses.default.canceled,
			color: 'red'
		}, {
			id: _rmaStatuses.default.completed,
			color: 'green'
		}],
		rmaApproval: [{
			id: _rmaApprovalStatuses.default.pending,
			color: 'yellow'
		}, {
			id: _rmaApprovalStatuses.default.denied,
			color: 'red'
		}, {
			id: _rmaApprovalStatuses.default.approved,
			color: 'green'
		}],
		swap: [{
			id: _swapStatuses.default.approved,
			color: 'yellow'
		}, {
			id: _swapStatuses.default.cancelled,
			color: 'red'
		}, {
			id: _swapStatuses.default.flagged,
			color: 'orange'
		}, {
			id: _swapStatuses.default.fulfilled,
			color: 'green'
		}, {
			id: _swapStatuses.default.cancelledAfterFulfilled,
			color: 'red'
		}],
		swapApproval: [{
			id: _swapApprovalStatuses.default.pending,
			color: 'yellow'
		}, {
			id: _swapApprovalStatuses.default.denied,
			color: 'red'
		}, {
			id: _swapApprovalStatuses.default.approved,
			color: 'green'
		}],
		boardingStatus: [{
			id: _boardingStatuses.default.boardingNotNeeded,
			color: 'lightgray'
		}, {
			id: _boardingStatuses.default.pending,
			color: 'yellow'
		}, {
			id: _boardingStatuses.default.boarded,
			color: 'green'
		}, {
			id: _boardingStatuses.default.boardingFailed,
			color: 'red'
		}]
	};
});