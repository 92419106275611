define('swap-system/controllers/rmas', ['exports', 'swap-system/mixins/controllers/ticket-list', 'swap-system/mixins/controllers/server-table-common-properties'], function (exports, _ticketList, _serverTableCommonProperties) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_ticketList.default, _serverTableCommonProperties.default, {
		routeName: 'rma',
		filterPropertyName: 'rmaStatus',
		modelName: 'rmaStatuses',
		templateName: 'ember-common-table/rmas',
		submittedAtPropertyName: 'submittedAt',
		submittedByPropertyName: 'submittedBy',
		useFilteringByColumns: true,
		focusGlobalFilter: true,
		sendDisplayDataChangedAction: true
	});
});