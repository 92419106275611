define('swap-system/routes/packing-slip', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var PACKING_SLIPS_URL = '/api/v1/packing-slips/';

	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, {
		requiredGroup: _userGroups.default.swapUser,
		ajax: Ember.inject.service(),
		session: Ember.inject.service(),
		model: function model(params) {
			var packingSlipRequestUrl = '' + PACKING_SLIPS_URL + params.id;

			return Ember.RSVP.hash({
				packingSlip: this.get('ajax').request(packingSlipRequestUrl).then(function (_ref) {
					var html = _ref.html;
					return html;
				})
			});
		}
	});
});