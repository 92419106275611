define('swap-system/components/status-label', ['exports', 'swap-system/enums/statusColors'], function (exports, _statusColors) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		modelName: null,
		statusName: null,
		status: null,
		statusColor: Ember.computed('status', function () {
			var status = this.get('status');
			var modelName = this.get('modelName');

			this.set('statusName', status.get('displayName'));

			return _statusColors.default[modelName].find(function (item) {
				return item.id === +status.get('id');
			}).color;
		})
	});
});