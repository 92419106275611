define('swap-system/adapters/altered-shipping-address', ['exports', 'swap-system/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var adminPanelUrl = 'adminPanel/shippingAddresses';

	exports.default = _application.default.extend({
		urlForFindAll: function urlForFindAll(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForQuery: function urlForQuery(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForQueryRecord: function urlForQueryRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		queryRecord: function queryRecord(store, type, query) {
			if (query.officeId) {
				var officeId = query.officeId;

				delete query.officeId;
				var url = this.buildURL(type.modelName, officeId, null, 'findRecord', null);
				var _res = this.ajax(url, 'GET', { data: query });

				return _res;
			}

			var res = this.ajax(this.buildURL(type.modelName, null, null, 'query', query), 'GET', { data: query });
			return res;
		},
		urlForCreateRecord: function urlForCreateRecord(id, modelName, snapshot) {
			var res = this._super(adminPanelUrl, modelName, snapshot);

			return res;
		},
		urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		}
	});
});