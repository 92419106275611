define('swap-system/models/swap-rma-substitution-product', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/validations/swap-parameters-validations'], function (exports, _emberData, _emberCpValidations, _swapParametersValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo,
	    attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany;


	var swapRmaSubstitutionProductValidations = Ember.assign({
		product: (0, _emberCpValidations.validator)('presence', true)
	}, _swapParametersValidations.default);

	var validations = (0, _emberCpValidations.buildValidations)(swapRmaSubstitutionProductValidations);

	exports.default = Model.extend(validations, {
		newSerialNumber: attr('string'),
		trackingNumber: attr('string'),
		terminalNumber: attr('string'),
		ipAddress: attr('string'),
		product: belongsTo('product'),
		swapRmaSubstitutionSubproducts: hasMany('swapRmaSubstitutionSubproduct'),
		conditionType: belongsTo('conditionType')
	});
});