define('swap-system/helpers/is-equal-time', ['exports', 'ember-date-components/helpers/is-equal-time'], function (exports, _isEqualTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isEqualTime.default;
    }
  });
  Object.defineProperty(exports, 'isEqualTime', {
    enumerable: true,
    get: function () {
      return _isEqualTime.isEqualTime;
    }
  });
});