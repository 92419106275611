define('swap-system/components/modal-window', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var TAB_KEY = 9;

	exports.default = Ember.Component.extend({
		modalListenerFunction: null,
		handleKeyDown: function handleKeyDown(event, element) {
			if (event.keyCode !== TAB_KEY) {
				return;
			}

			var focusableElements = this.findFocusableElements(element);

			if (!focusableElements.length) {
				event.preventDefault();

				return;
			}

			if (event.shiftKey) {
				this.handleBackwardTab(event, focusableElements);
			} else {
				this.handleForwardTab(event, focusableElements);
			}
		},
		findFocusableElements: function findFocusableElements(element) {
			return element.querySelectorAll('\n\t\t\ta[href],\n\t\t\tinput:not([disabled]),\n\t\t\tselect:not([disabled]),\n\t\t\ttextarea:not([disabled]),\n\t\t\tbutton:not([disabled]),\n\t\t\t[tabindex]:not([tabindex="-1"]),\n\t\t\t.ember-power-select-trigger\n\t\t');
		},
		handleBackwardTab: function handleBackwardTab(event, focusableElements) {
			var firstFocusableElement = focusableElements[0];
			var lastFocusableElement = focusableElements[focusableElements.length - 1];

			if (document.activeElement === firstFocusableElement) {
				event.preventDefault();

				return lastFocusableElement.focus();
			}

			if ([].indexOf.call(focusableElements, document.activeElement) === -1) {
				event.preventDefault();

				return lastFocusableElement.focus();
			}
		},
		handleForwardTab: function handleForwardTab(event, focusableElements) {
			var firstFocusableElement = focusableElements[0];
			var lastFocusableElement = focusableElements[focusableElements.length - 1];

			if (document.activeElement === lastFocusableElement) {
				event.preventDefault();

				return firstFocusableElement.focus();
			}

			if ([].indexOf.call(focusableElements, document.activeElement) === -1) {
				event.preventDefault();

				return firstFocusableElement.focus();
			}
		},

		actions: {
			onShow: function onShow(event) {
				var _this = this;

				var elementId = event.elementId;
				var modal = document.getElementById(elementId);

				this.set('modalListenerFunction', function (modalEvent) {
					return _this.handleKeyDown(modalEvent, modal);
				});
				window.addEventListener('keydown', this.modalListenerFunction);
			},
			onHide: function onHide() {
				window.removeEventListener('keydown', this.modalListenerFunction);
			}
		}
	});
});