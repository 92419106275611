define('swap-system/authenticators/sso', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _Ember$RSVP = Ember.RSVP,
	    reject = _Ember$RSVP.reject,
	    resolve = _Ember$RSVP.resolve;
	exports.default = _base.default.extend({
		ajax: Ember.inject.service(),
		user: Ember.inject.service(),
		restore: function restore(_ref) {
			var token = _ref.token;

			return token ? this.get('user').fetchUserData(token).then(function () {
				return { token: token };
			}) : reject();
		},
		authenticate: function authenticate(token) {
			return token ? this.get('user').fetchUserData(token).then(function () {
				return { token: token };
			}) : reject();
		},
		invalidate: function invalidate() {
			return this.get('ajax').post('/api/v1/auth/logout').catch(function () {
				return resolve();
			});
		}
	});
});