define("swap-system/enums/swapStatuses", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		approved: 1,
		cancelled: 2,
		flagged: 3,
		fulfilled: 4,
		cancelledAfterFulfilled: 5
	};
});