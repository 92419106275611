define('swap-system/adapters/merchant', ['exports', 'swap-system/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		merchant: Ember.inject.service('merchant'),
		urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
			var merchant = this.get('merchant');
			return '' + this._super(merchant.get('id'), modelName, snapshot);
		},
		urlForFindAll: function urlForFindAll(id, modelName, snapshot) {
			var merchant = this.get('merchant');
			return '' + this._super(merchant.get('id'), modelName, snapshot);
		}
	});
});