define('swap-system/mixins/controllers/out-of-warranty', ['exports', 'swap-system/enums/brands', 'swap-system/config/environment'], function (exports, _brands, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var CURVEPAY_MID_LENGTH = 16;

	exports.default = Ember.Mixin.create({
		isOutOfWarranty: Ember.computed('model.swapApproval', function () {
			if (!_environment.default.features['out-of-warranty']) {
				return false;
			}

			var isHarbortouchBrand = +this.get('model.swapApproval.brand.id') === _brands.default.Harbortouch;
			var isCurvepayMerchant = this.get('model.swapApproval.mid').length === CURVEPAY_MID_LENGTH;
			var isFeePayed = this.get('model.merchant.isFeePayed');

			return isHarbortouchBrand && !isCurvepayMerchant && !isFeePayed;
		})
	});
});