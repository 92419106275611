define('swap-system/components/read-more', ['exports', 'swap-system/templates/components/read-more'], function (exports, _readMore) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _readMore.default,
		classNames: 'read-more',
		classNameBindings: ['isOpen:is-open:is-closed'],
		content: null,
		isOpenDefault: true,
		isOpen: true,
		maxHeight: 100,
		showReadMore: false,
		toggleClass: 'read-more-toggle',
		openText: 'Read more...',
		closeText: 'Close',
		toggleText: Ember.computed('openText', 'closeText', 'isOpen', function () {
			return this.get('isOpen') ? this.get('closeText') : this.get('openText');
		}),
		didInsertElement: function didInsertElement() {
			Ember.run.scheduleOnce('afterRender', this, 'showText');
		},
		showText: function showText() {
			this.set('showReadMore', this.$()[0].clientHeight > this.get('maxHeight') ? true : false);
			this.toggleProperty('isOpen');
		},


		bodyClass: 'read-more-body',

		actions: {
			toggle: function toggle() {
				this.toggleProperty('isOpen');
			}
		}
	});
});