define('swap-system/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany;
	exports.default = Model.extend({
		modelNo: attr(),
		isSerialNeeded: attr('boolean'),
		source: attr('string'),
		productName: attr('string'),
		productType: attr('string'),
		rmaCost: attr('string'),
		cancelRmaCost: attr('string'),
		createRma: attr('boolean'),
		equipment: hasMany('equipment'),
		swapDetails: hasMany('swapDetail'),
		swapApprovalDetails: hasMany('swapApprovalDetail'),
		rmaDetails: hasMany('rmaDetail'),
		rmaApprovalDetails: hasMany('rmaApprovalDetail'),
		swapRmaSubstitutionProduct: hasMany('swapRmaSubstitutionProduct'),
		swapRmaSubstitutionSubproduct: hasMany('swapRmaSubstitutionSubproduct'),
		adminPanelProductSubstitutionProduct: hasMany('adminPanelProductSubstitutionProduct'),
		adminPanelProductSubstitutionSubproduct: hasMany('adminPanelProductSubstitutionSubproduct')
	});
});