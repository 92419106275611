define('swap-system/mixins/controllers/ticket-list', ['exports', 'moment', 'swap-system/enums/vipOptions', 'swap-system/enums/availabilityOptions', 'swap-system/mixins/controllers/hide-show'], function (exports, _moment, _vipOptions, _availabilityOptions, _hideShow) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var get = Ember.get,
	    setProperties = Ember.setProperties,
	    computed = Ember.computed;

	var EMPTY_FILTER_VALUE = '';
	var EMPTY_DATE_FILTER_VALUE = {
		type: 'dateRange',
		columnName: 'dueDate',
		start: null,
		end: null
	};

	exports.default = Ember.Mixin.create(_hideShow.default, {
		filterString: '',
		columnFilters: {},
		routeName: 'approve',
		columnsAreUpdateable: true,
		columnFieldsToCheckUpdate: ['disableSorting', 'isHidden'],
		filterPropertyName: 'swapApprovalStatus',
		modelName: 'swapApprovalStatuses',
		templateName: 'ember-common-table/swap-approvals',
		submittedAtPropertyName: 'createdAt',
		submittedByPropertyName: 'createdBy',
		columns: computed('model', function () {
			var routeName = this.get('routeName');
			var filterPropertyName = this.get('filterPropertyName');
			var modelName = this.get('modelName');
			var templateName = this.get('templateName');
			var submittedAtPropertyName = this.get('submittedAtPropertyName');
			var submittedByPropertyName = this.get('submittedByPropertyName');
			var isRmaRoute = routeName === 'rma' || routeName === 'rmaApprove';
			var dueDateColumn = null;
			var rmaReasonColumn = null;
			var swapColumn = null;
			var swapApprovalColumn = null;
			var rmaColumn = null;
			var rmaApprovalColumn = null;
			var vipColumn = null;
			var hideDefaultDate = true;
			var filterStrings = {
				brandId: EMPTY_FILTER_VALUE,
				dba: EMPTY_FILTER_VALUE,
				id: EMPTY_FILTER_VALUE,
				isVip: EMPTY_FILTER_VALUE,
				mid: EMPTY_FILTER_VALUE,
				orderTypeId: EMPTY_FILTER_VALUE,
				statusId: EMPTY_FILTER_VALUE,
				submittedAt: EMPTY_DATE_FILTER_VALUE,
				submittedBy: EMPTY_FILTER_VALUE,
				swapApprovalFilterString: EMPTY_FILTER_VALUE,
				rmaFilterString: EMPTY_FILTER_VALUE
			};

			var swapPageParams = this.get('swapPageParams');

			if (swapPageParams && routeName === 'swap') {
				var _swapPageParams$colum = swapPageParams.columnFilters,
				    columnFilters = _swapPageParams$colum === undefined ? {} : _swapPageParams$colum;
				var submittedAt = columnFilters.submittedAt;


				hideDefaultDate = !(submittedAt && submittedAt.start);

				filterStrings = {
					brandId: columnFilters.brandId || EMPTY_FILTER_VALUE,
					dba: columnFilters.dba || EMPTY_FILTER_VALUE,
					id: columnFilters.id || EMPTY_FILTER_VALUE,
					isVip: columnFilters.isVip || EMPTY_FILTER_VALUE,
					mid: columnFilters.mid || EMPTY_FILTER_VALUE,
					orderTypeId: columnFilters.orderTypeId || EMPTY_FILTER_VALUE,
					statusId: columnFilters.swapStatusId || EMPTY_FILTER_VALUE,
					submittedAt: columnFilters.submittedAt || EMPTY_DATE_FILTER_VALUE,
					submittedBy: columnFilters.submittedBy || EMPTY_FILTER_VALUE,
					swapApprovalFilterString: columnFilters['swapApproval.id'] || EMPTY_FILTER_VALUE,
					rmaFilterString: columnFilters['rma.id'] || EMPTY_FILTER_VALUE
				};
			}

			if (routeName === 'rma') {
				dueDateColumn = {
					propertyName: 'dueDate',
					title: 'Due Date',
					isHidden: false,
					template: 'ember-common-table/rma-due-date',
					hideFieldValue: true,
					cancelLabel: 'All',
					filterString: {
						type: 'dateRange',
						columnName: 'dueDate',
						start: null,
						end: null
					},
					filterWithDate: true,
					dateRanges: {
						'Overdue': [(0, _moment.default)(new Date('2016-01-01T00:00:00')).format('MM/DD/YYYY'), (0, _moment.default)().startOf('day').format('MM/DD/YYYY')],
						'Due today': [(0, _moment.default)().startOf('day').format('MM/DD/YYYY'), (0, _moment.default)().endOf('day').format('MM/DD/YYYY')]
					}
				};
			}

			if (routeName === 'rma') {
				rmaApprovalColumn = {
					propertyName: 'rmaApproval.id',
					filteredBy: 'rmaApproval',
					title: 'RMA Approval',
					isHidden: false,
					template: 'ember-common-table/rma-approval'
				};
			}

			if (routeName === 'swap') {
				swapApprovalColumn = {
					propertyName: 'swapApproval.id',
					filteredBy: 'swapApproval',
					title: 'Swap Approval',
					isHidden: false,
					routeName: 'approve',
					filterString: filterStrings.swapApprovalFilterString
				};
			}

			if (routeName === 'rma' || routeName === 'approve') {
				swapColumn = {
					propertyName: 'swap.id',
					filteredBy: 'swap',
					title: 'Swap',
					isHidden: false,
					template: 'ember-common-table/swap'
				};
			}

			if (routeName === 'swap' || routeName === 'rmaApprove') {
				rmaColumn = {
					propertyName: 'rma.id',
					filteredBy: 'rma',
					title: 'RMA',
					isHidden: false,
					template: 'ember-common-table/rma',
					filterString: filterStrings.rmaFilterString
				};
			}

			if (routeName === 'swap' || routeName === 'approve') {
				vipColumn = {
					propertyName: 'isVip',
					title: 'VIP',
					isHidden: false,
					template: 'ember-common-table/vip',
					filterWithSelect: true,
					predefinedFilterOptions: _vipOptions.default,
					filterStrings: filterStrings.isVip
				};
			}

			if (isRmaRoute) {
				rmaReasonColumn = {
					propertyName: 'rmaReason.description',
					filteredBy: 'rmaReason',
					title: 'Type',
					isHidden: false,
					template: 'ember-common-table/reason',
					filterWithSelect: true,
					filterSelectDisplayProperty: 'description',
					type: 'number',
					predefinedFilterOptions: get(this, 'model.rmaReasons').map(function (x) {
						return { label: get(x, 'description'), value: get(x, 'id') };
					})
				};
			}

			if (routeName === 'adminPanelProduct') {
				var adminPanelProductColumns = [{
					propertyName: 'id',
					title: 'ID',
					isHidden: false,
					mayBeHidden: false,
					routeName: routeName,
					disableSorting: true
				}, {
					propertyName: 'productName',
					title: 'Product name',
					isHidden: false,
					disableSorting: true
				}, {
					propertyName: 'modelNo',
					title: 'Model',
					isHidden: false,
					disableSorting: true
				}, {
					propertyName: 'subProducts',
					title: 'Subproducts',
					isHidden: false,
					template: 'ember-common-table/admin-panel-subproducts',
					disableSorting: true,
					disableFiltering: true
				}, {
					propertyName: 'availableSubstitutions',
					title: 'Available Substitutions',
					isHidden: false,
					template: 'ember-common-table/admin-panel-available-substitutions',
					disableSorting: true,
					disableFiltering: true
				}, {
					propertyName: 'isAutoApprove',
					title: 'Auto Swap Approval',
					isHidden: false,
					disableSorting: true,
					filterWithSelect: true,
					predefinedFilterOptions: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
					template: 'ember-common-table/admin-panel-auto-approval'
				}];

				if (this.isHideShowEnabled) {
					adminPanelProductColumns.push({
						propertyName: 'isAvailable',
						title: 'Availability',
						isHidden: false,
						disableSorting: true,
						filterWithSelect: true,
						predefinedFilterOptions: _availabilityOptions.default,
						template: 'ember-common-table/admin-panel-availability'
					});
				}

				return adminPanelProductColumns;
			}

			if (routeName === 'adminPanelAlteredShippingAddress') {
				return [{
					propertyName: 'officeId',
					title: 'Office ID',
					isHidden: false,
					mayBeHidden: false,
					template: 'ember-common-table/admin-panel-altered-shipping-address-office-id'
				}, {
					propertyName: 'officeName',
					title: 'Office name',
					isHidden: false
				}, {
					propertyName: 'defaultShippingAddress',
					title: 'Default Shipping Address',
					isHidden: false,
					template: 'ember-common-table/admin-panel-default-shipping-address'
				}, {
					propertyName: 'isLocked',
					title: 'is Locked',
					isHidden: false,
					template: 'ember-common-table/admin-panel-is-locked'
				}];
			}

			var result = [{
				propertyName: 'id',
				title: 'ID',
				isHidden: false,
				mayBeHidden: false,
				routeName: routeName,
				filterString: filterStrings.id
			}, {
				propertyName: filterPropertyName + 'Id',
				filteredBy: filterPropertyName,
				title: 'Status',
				isHidden: false,
				template: templateName,
				filterWithSelect: true,
				filterSelectDisplayProperty: 'displayName',
				type: 'number',
				predefinedFilterOptions: get(this, 'model.' + modelName).map(function (x) {
					return { label: get(x, 'displayName'), value: get(x, 'id') };
				}),
				filterString: filterStrings.statusId
			}, {
				propertyName: 'brandId',
				filteredBy: 'brand',
				title: 'Brand',
				isHidden: false,
				template: 'ember-common-table/brand',
				filterWithSelect: true,
				filterSelectDisplayProperty: 'name',
				type: 'number',
				predefinedFilterOptions: get(this, 'model.brands').map(function (x) {
					return { label: get(x, 'name'), value: get(x, 'id') };
				}),
				filterString: filterStrings.brandId
			}, {
				propertyName: 'orderTypeId',
				filteredBy: 'orderType',
				title: 'Order Type',
				isHidden: isRmaRoute,
				template: 'ember-common-table/order-type',
				filterWithSelect: true,
				filterSelectDisplayProperty: 'displayName',
				type: 'number',
				predefinedFilterOptions: get(this, 'model.orderTypes').map(function (x) {
					return { label: get(x, 'displayName'), value: get(x, 'id') };
				}),
				filterString: filterStrings.orderTypeId
			}, {
				propertyName: 'processorType.displayName',
				filteredBy: 'processorType',
				title: 'Processor',
				isHidden: true,
				template: 'ember-common-table/processor-type',
				filterWithSelect: true,
				filterSelectDisplayProperty: 'displayName',
				type: 'number',
				predefinedFilterOptions: get(this, 'model.processorTypes').map(function (x) {
					return { label: get(x, 'displayName'), value: get(x, 'id') };
				})
			}, {
				propertyName: submittedByPropertyName,
				title: 'Submitted By',
				isHidden: false,
				filterString: filterStrings.submittedBy
			}, {
				propertyName: submittedAtPropertyName,
				title: 'Submitted At',
				filterWithDate: true,
				hideFieldValue: hideDefaultDate,
				cancelLabel: 'Clear',
				filterString: filterStrings.submittedAt,
				dateRanges: {
					'Today': [(0, _moment.default)().startOf('day').format('MM/DD/YYYY'), (0, _moment.default)().endOf('day').format('MM/DD/YYYY')],
					'Yesterday': [(0, _moment.default)().subtract(1, 'days').startOf('day').format('MM/DD/YYYY'), (0, _moment.default)().subtract(1, 'days').endOf('day').format('MM/DD/YYYY')],
					'Last 7 Days': [(0, _moment.default)().subtract(7, 'days').format('MM/DD/YYYY'), (0, _moment.default)().format('MM/DD/YYYY')],
					'Last 30 Days': [(0, _moment.default)().subtract(30, 'days').format('MM/DD/YYYY'), (0, _moment.default)().format('MM/DD/YYYY')],
					'Last 90 Days': [(0, _moment.default)().subtract(90, 'days').format('MM/DD/YYYY'), (0, _moment.default)().format('MM/DD/YYYY')]
				}
			}, {
				propertyName: 'dba',
				title: 'DBA Name',
				isHidden: false,
				filterString: filterStrings.dba
			}, {
				propertyName: 'mid',
				title: 'Merchant ID',
				isHidden: false,
				filterString: filterStrings.mid
			}];

			if (dueDateColumn) {
				result.push(dueDateColumn);
			}

			if (rmaReasonColumn) {
				result.push(rmaReasonColumn);
			}

			if (rmaApprovalColumn) {
				result.push(rmaApprovalColumn);
			}

			if (swapApprovalColumn) {
				result.push(swapApprovalColumn);
			}

			if (rmaColumn) {
				result.push(rmaColumn);
			}

			if (swapColumn) {
				result.push(swapColumn);
			}

			if (vipColumn) {
				result.push(vipColumn);
			}

			return result;
		}),
		actions: {
			displayDataChanged: function displayDataChanged(table) {
				var columnFilters = table.columnFilters;

				this.get('columns').forEach(function (column) {
					Ember.set(column, 'disableSorting', typeof columnFilters[column.propertyName] != 'undefined');
					Ember.set(column, 'isHidden', column.isHidden ? typeof columnFilters[column.propertyName] == 'undefined' : column.isHidden);
					Ember.set(column, 'filterString', columnFilters[column.propertyName]);
					Ember.set(column, 'hideFieldValue', column.filterString && column.filterString.type == 'dateRange' && !column.filterString.start);

					if (column.hideFieldValue) {
						delete columnFilters[column.propertyName];
					}
				});

				setProperties(this, {
					columnFilters: columnFilters,
					filterString: table.filterString
				});
			},
			columnsVisibilityChanged: function columnsVisibilityChanged(table) {
				this.get('columns').forEach(function (column) {
					Ember.set(column, 'isHidden', table.findBy('propertyName', column.propertyName).isHidden);
				});
			}
		}
	});
});