define('swap-system/helpers/timestamp-format', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			var dateString = '';
			if (params[0]) {
				dateString = new Date(params[0]).toLocaleString();
			}
			return dateString;
		}
	});
});