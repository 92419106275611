define('swap-system/helpers/transform-substitution-subproducts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		user: Ember.inject.service(),
		compute: function compute(params) {
			var substitutionSubproducts = params[0];

			return substitutionSubproducts.map(function (element) {
				return element.get('product').content;
			});
		}
	});
});