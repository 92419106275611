define('swap-system/routes/search', ['exports', 'swap-system/mixins/routes/ticket-search-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/enums/userGroups'], function (exports, _ticketSearchRouteMixin, _requiredGroupMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketSearchRouteMixin.default, _requiredGroupMixin.default, {
		requiredGroup: _userGroups.default.swapUser,
		titleToken: function titleToken() {
			return 'New Swap';
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.setProperties({
				search: ''
			});
		}
	});
});