define("swap-system/enums/brands", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		Harbortouch: 1,
		restaurantManager: 2,
		POSitouch: 3,
		FuturePOS: 4,
		Shift4: 5
	};
});