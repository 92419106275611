define('swap-system/components/rma-fees-tbl', ['exports', 'swap-system/mixins/controllers/due-date', 'swap-system/enums/userGroups'], function (exports, _dueDate, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_dueDate.default, {
		userGroups: _userGroups.default,
		rmaStatusId: null,
		rmaFees: [],
		totalBillAmount: Ember.computed('rmaFees.@each.billAmount', function () {
			return this.get('rmaFees').mapBy('billAmount').reduce(function (sum, e) {
				return Math.round((+sum + +e) * 100) / 100;
			}, 0);
		}),
		actions: {
			setNotApplicable: function setNotApplicable(fee) {
				fee.set('isApplicable', false);
			},
			setBilled: function setBilled(feeName) {
				var rmaFees = this.get('rmaFees');
				var fee = rmaFees.findBy('feeName', feeName);

				fee.set('isBilled', true);
			}
		}
	});
});