define('swap-system/helpers/lookup-equipment-value', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			var _params = _slicedToArray(params, 3),
			    value = _params[0],
			    equipmentList = _params[1],
			    equipmentId = _params[2];

			if (!equipmentList || !equipmentId || !value) {
				return null;
			}

			var equipment = equipmentList.findBy('equipmentId', parseInt(equipmentId, 10));

			return equipment ? equipment.get(value) : null;
		}
	});
});