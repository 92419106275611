define('swap-system/enums/trackingApis', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		ups: {
			patterns: [/^(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)$/],
			url: 'http://wwwapps.ups.com/etracking/tracking.cgi?tracknum={{trackingNumber}}'
		},
		fedex: {
			patterns: [/(^\b96\d{20}\b$)|(^\b\d{15}\b$)|(^\b\d{12}\b$)/, /^\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b$/, /^[0-9]{15}$/],
			url: 'https://www.fedex.com/apps/fedextrack/?tracknumbers={{trackingNumber}}'
		}
	};
});