define('swap-system/serializers/swap', ['exports', 'ember-data', 'swap-system/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			swapDetails: { embedded: 'always' },
			swapComments: { embedded: 'always' },
			rma: { serialize: 'id', deserialize: 'records' },
			swapApproval: { serialize: 'id', deserialize: 'records' },
			file: { serialize: 'id', deserialize: 'id' },
			brand: { serialize: 'id', deserialize: 'records' },
			swapStatus: { serialize: 'id', deserialize: 'records' },
			orderType: { serialize: 'id', deserialize: 'records' },
			processorType: { serialize: 'id', deserialize: 'records' }
		}
	});
});