define('swap-system/transforms/utc-format', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized) {
			return (0, _moment.default)(serialized).tz('America/New_York').format('M/DD/YYYY, h:mm A z');
		},
		serialize: function serialize(deserialized) {
			return (0, _moment.default)(deserialized).toISOString();
		}
	});
});