define('swap-system/utils/utils', ['exports'], function (exports) {
	'use strict';

	// semantic-ui modal timeout
	exports.waitForModalAnimation = function (callback) {
		return Ember.run.later(function () {
			callback();
		}, 400);
	};

	exports.promptBeforeNavigation = function (prompt) {
		window.onbeforeunload = function () {
			return prompt;
		};
	};

	exports.clearNavigationPrompt = function () {
		window.onbeforeunload = null;
	};
});