define('swap-system/helpers/not-eq', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			var val = params[0];
			var arr = Array.prototype.slice.call(params, 1);
			return arr.filter(function (a) {
				return a == val;
			}).length == 0;
		}
	});
});