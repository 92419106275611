define('swap-system/models/merchant', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend({
		mid: attr('string'),
		status: attr('number'),
		ownerFirstName: attr('string'),
		ownerLastName: attr('string'),
		dbaName: attr('string'),
		isoFirstName: attr('string'),
		isoLastName: attr('string'),
		isoName: attr('string'),
		isoOfficeName: attr('string'),
		isoAttention: attr('string'),
		isoHierGroup: attr('string'),
		isoHierChannel: attr('string'),
		isoHierSource: attr('string'),
		isoHierPartner: attr('string'),
		isoStatus: attr('number'),
		addresses: attr(),
		bankRecordExists: attr(),
		dbaAddress1: attr('string'),
		dbaAddress2: attr('string'),
		dbaCity: attr('string'),
		dbaState: attr('string'),
		dbaZip: attr('string'),
		dbaExtendedZip: attr('string'),
		dbaAttention: attr('string'),
		associate: attr('string'),
		chain: attr('string'),
		isFeePayed: attr('boolean'),
		fullName: Ember.computed('ownerFirstName', 'ownerLastName', function () {
			return this.get('ownerFirstName') + ' ' + this.get('ownerLastName');
		}),
		isActive: Ember.computed('status', function () {
			return this.get('status') === 330 || this.get('status') === 350 || this.get('status') >= 600;
		}),
		isVipMember: attr('boolean'),
		officeId: attr('string'),
		otherAttention: attr('string'),
		isAddressLocked: attr('boolean'),
		defaultAddress: attr('string'),
		partnerLevel: attr('string')
	});
});