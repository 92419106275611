define('swap-system/mixins/controllers/add-equipment', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var defaultCost = '0.00';

	exports.default = Ember.Mixin.create({
		actions: {
			showAddItemModal: function showAddItemModal() {
				Ember.$('.ui.modal.add.item').modal('show');
			},
			addItem: function addItem() {
				var _this = this;

				this.store.findRecord('product', this.get('equipmentToAdd.id')).then(function (product) {
					_this.set('model.lastManualSourceId', _this.get('model.lastManualSourceId') + 1);
					var equipment = _this.store.createRecord('equipment', {
						orderId: null,
						orderDetailId: _this.get('model.lastManualSourceId'),
						product: _this.store.peekRecord('product', product.get('id')),
						modelNo: product.get('modelNo'),
						oldSerialNo: _this.get('manualSerial'),
						isSerialNeeded: product.get('isSerialNeeded'),
						source: 'Manual',
						productName: product.get('productName'),
						rmaCost: product.get('rmaCost') || defaultCost,
						cancelRmaCost: product.get('cancelRmaCost') || defaultCost,
						createRma: product.get('createRma')
					});
					_this.get('model.equipment').pushObject(equipment);
					_this.set('equipmentToAdd', null);
					_this.set('manualSerial', null);
				});
			}
		}
	});
});