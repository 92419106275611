define('swap-system/helpers/expand-line-breaks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.expandLineBreaks = expandLineBreaks;


  /**
   * [expandLineBreaks description]
   * @method  expandLineBreaks
   * @param   {[type]}         params [description]
   * @param   {[type]}         hash   [description]
   * @returns {[type]}                [description]
   */
  function expandLineBreaks(params, hash) {
    if (hash && hash.content) {
      return Ember.String.htmlSafe(hash.content.replace(/\n/g, '<br>'));
    }

    return '';
  }

  exports.default = Ember.Helper.helper(expandLineBreaks);
});