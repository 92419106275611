define('swap-system/mixins/controllers/substitution-dropdown', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		actions: {
			substitutionDropdownChangeHandler: function substitutionDropdownChangeHandler(swapDetail, keyToSet, selectedValue) {
				var _this = this;

				var swapRmaSubstitutionProduct = swapDetail.get('swapRmaSubstitutionProduct');
				var swapRmaSubstitutionProductId = swapDetail.get('swapRmaSubstitutionProduct.id');
				var products = this.get('model.products');
				var swapRmaSubstitutionSubproducts = [];

				keyToSet.substitutionSubproductIds.forEach(function (subproductId) {
					var subproduct = products.findBy('id', subproductId);

					var swapRmaSubstitutionSubproduct = _this.store.createRecord('swapRmaSubstitutionSubproduct', {
						product: subproduct,
						newSerialNumber: null,
						trackingNumber: null,
						swapRmaSubstitutionProductId: swapRmaSubstitutionProductId
					});

					swapRmaSubstitutionSubproducts.push(swapRmaSubstitutionSubproduct);
				});

				var newSwapRmaSubstitutionProduct = products.findBy('id', keyToSet.substitutionProductId);

				swapRmaSubstitutionProduct.setProperties({
					product: newSwapRmaSubstitutionProduct,
					newSerialNumber: null,
					trackingNumber: null,
					swapRmaSubstitutionSubproducts: swapRmaSubstitutionSubproducts
				});

				selectedValue.selected.set('content', newSwapRmaSubstitutionProduct);
			}
		}
	});
});