define('swap-system/models/equipment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		orderId: attr('number'),
		orderDetailId: attr('number'),
		modelNo: attr(),
		oldSerialNo: attr('string'),
		quantity: attr('string'),
		isSerialNeeded: attr('boolean'),
		swapThis: attr('boolean', { defaultValue: false }),
		source: attr('string'),
		productName: attr('string'),
		rmaCost: attr('string'),
		cancelRmaCost: attr('string'),
		createRma: attr('boolean'),
		substitutionProduct: attr(),
		product: belongsTo('product', { inverse: 'equipment' })
	});
});