define('swap-system/adapters/product', ['exports', 'swap-system/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		merchant: Ember.inject.service('merchant'),
		urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
			var merchant = this.get('merchant');
			return this._super(id, modelName, snapshot) + '?mid=' + merchant.get('id');
		},
		urlForFindAll: function urlForFindAll(id, modelName, snapshot) {
			var merchant = this.get('merchant');
			var mid = merchant.get('id');

			if (mid) {
				return this._super(id, modelName, snapshot) + '?mid=' + mid;
			}

			return '' + this._super(id, modelName, snapshot);
		}
	});
});