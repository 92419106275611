define('swap-system/initializers/inject-notifications', ['exports', 'ember-common/initializers/inject-notifications'], function (exports, _injectNotifications) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _injectNotifications.default;
    }
  });
});