define('swap-system/mixins/models/commit-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		commitDetails: function commitDetails() {
			var modelName = Ember.String.camelize(this.constructor.modelName);
			this.get(modelName + 'Details').filterBy('hasDirtyAttributes').forEach(function (model) {
				model.send('willCommit');
				model.set('_internalModel._attributes', {});
				model.send('didCommit');
			});
		}
	});
});