define('swap-system/components/equipment-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		equipmentToAdd: null,
		manualSerial: null,
		frequentlyUsedProducts: ['Verifone VX520 CTLS', 'PAX S90', 'PAX S80'],
		groupedProducts: Ember.computed('products', 'frequentlyUsedProducts', function () {
			var frequentlyUsedProductNames = this.get('frequentlyUsedProducts');
			var allProducts = this.get('products');
			var frequentlyUsedProducts = allProducts.filter(function (item) {
				return frequentlyUsedProductNames.includes(item.get('productName')) && item.get('modelNo') === null;
			});
			return [{
				groupName: 'Frequently Used',
				disabled: frequentlyUsedProducts.length === 0,
				options: frequentlyUsedProducts.length > 0 ? frequentlyUsedProducts : ['None']
			}, {
				groupName: 'All',
				options: allProducts.toArray()
			}];
		}),
		actions: {
			addEquipment: function addEquipment() {
				this.addItem();
			}
		}
	});
});