define('swap-system/models/swap-detail', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/validations/swap-parameters-validations'], function (exports, _emberData, _emberCpValidations, _swapParametersValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var swapDetailValidations = Ember.assign({
		equipmentId: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Equipment ID" is required'
		}),
		equipmentSource: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Equipment Source" is required'
		}),
		productName: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Product Name" is required'
		}),
		isSerialNeeded: (0, _emberCpValidations.validator)('inclusion', {
			allowBlank: true,
			in: [true, false],
			message: '"Is Serial Needed" must be either true or false'
		}),
		ipAddress: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			regex: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
		}),
		product: (0, _emberCpValidations.validator)('presence', true),
		swap: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)],
		swapRmaSubstitutionProductId: (0, _emberCpValidations.validator)('format', {
			allowBlank: true
		})
	}, _swapParametersValidations.default);

	var validations = (0, _emberCpValidations.buildValidations)(swapDetailValidations);

	exports.default = Model.extend(validations, {
		equipmentId: attr('string'),
		boardingMessage: attr('string'),
		orderId: attr('string'),
		equipmentSource: attr('string'),
		modelName: attr('string'),
		productName: attr('string'),
		isSerialNeeded: attr('boolean'),
		trackingNumber: attr('string'),
		terminalNumber: attr('string'),
		ipAddress: attr('string'),
		createdAt: attr('date'),
		swapRmaSubstitutionProductId: attr('number'),
		product: belongsTo('product', { inverse: 'swapDetails' }),
		swap: belongsTo('swap'),
		swapRmaSubstitutionProduct: belongsTo('swapRmaSubstitutionProduct'),
		boardingStatus: belongsTo('boardingStatus')
	});
});