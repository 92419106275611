define('swap-system/models/substitution-product', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		originalProduct: belongsTo('product', { inverse: null }),
		substitutionProduct: belongsTo('product', { inverse: null })
	});
});