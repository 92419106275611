define('swap-system/adapters/admin-panel-product', ['exports', 'swap-system/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var adminPanelUrl = 'adminPanel/products';

	exports.default = _application.default.extend({
		urlForFindAll: function urlForFindAll(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForQuery: function urlForQuery(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForQueryRecord: function urlForQueryRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForCreateRecord: function urlForCreateRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		},
		urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
			var res = this._super(id, adminPanelUrl, snapshot);

			return res;
		}
	});
});