define('swap-system/mixins/controllers/unload-ticket', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		unloadTicketDetails: function unloadTicketDetails() {
			var ticketModel = this.get('ticketModel');
			var model = this.get('model.' + ticketModel);
			var details = model.get(ticketModel + 'Details').toArray();

			// clear belongsTo
			details.setEach('' + ticketModel, null);

			// clear hasMany
			model.get(ticketModel + 'Details').clear();

			this.store.unloadAll(ticketModel + 'Detail');
		},
		unloadTicketComments: function unloadTicketComments() {
			var ticketModel = this.get('ticketModel');
			var model = this.get('model.' + ticketModel);
			var comments = model.get(ticketModel + 'Comments').toArray();

			// clear belongsTo
			comments.setEach('' + ticketModel, null);

			// clear hasMany
			model.get(ticketModel + 'Comments').clear();

			this.store.unloadAll(ticketModel + 'Comment');
		},
		unloadTicket: function unloadTicket() {
			var ticketModel = this.get('ticketModel');
			this.store.unloadAll('' + ticketModel);
		}
	});
});