define('swap-system/mixins/controllers/set-merchant-address', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_DBA_ADDRESS_TYPES = ['DBA Address', 'Legal Address', 'ISO Address', 'Other Address'];
	var ADDRESS_TYPES_NAMES = ['dba', 'legal', 'iso', 'other'];
	var addressTypes = [ADDRESS_TYPES_NAMES[0], ADDRESS_TYPES_NAMES[1], ADDRESS_TYPES_NAMES[2], ADDRESS_TYPES_NAMES[3]];

	exports.default = Ember.Mixin.create({
		chosenAddress: null,
		dbaAddressTypes: [],
		customAddressMessage: '',
		isSubmitBlocked: false,
		isAddressChangingBlocked: false,
		selectPlaceHolder: Ember.computed('chosenAddress', function () {
			if (!this.get('chosenAddress')) {
				return 'Custom Address';
			}
		}),
		merchantOfficeIdRegulationsObserver: Ember.observer('model.merchant.officeId', function () {
			var _get$getProperties = this.get('model.merchant').getProperties(['officeId', 'addresses', 'isAddressLocked']),
			    addresses = _get$getProperties.addresses,
			    isAddressLocked = _get$getProperties.isAddressLocked;

			var dbaAddressTypes = DEFAULT_DBA_ADDRESS_TYPES.reduce(function (result, value, index) {
				if (addresses.find(function (_ref) {
					var addressType = _ref.addressType;
					return addressType === addressTypes[index];
				})) {
					result.push(value);
				}

				return result;
			}, []);

			this.setProperties({
				dbaAddressTypes: dbaAddressTypes,
				isAddressChangingBlocked: isAddressLocked
			});
		}),
		checkSameAddresses: function checkSameAddresses(merchantAddresses, enteredAddress) {
			var addressProps = ['address1', 'address2', 'city', 'state', 'zip'];

			return merchantAddresses.find(function (merchantAddress) {
				return addressProps.every(function (prop) {
					merchantAddress[prop] = merchantAddress[prop] || '';
					enteredAddress[prop] = enteredAddress[prop] || '';

					return merchantAddress[prop] === enteredAddress[prop];
				});
			});
		},
		setShippingAddress: function setShippingAddress() {
			if (!this.get('chosenAddress')) {
				return;
			}

			var ticketModel = this.get('ticketModel');
			var chosenAddress = this.get('chosenAddress').toLowerCase();
			var merchantAddresses = this.get('model.' + ticketModel + '.merchantAddresses');
			var addressType = addressTypes.find(function (item) {
				return chosenAddress.match(item);
			});

			merchantAddresses.forEach(function (item) {
				item.addressType = item.addressType.toLowerCase();
			});

			var merchantAddress = merchantAddresses.findBy('addressType', addressType);

			this.get('model.' + this.get('ticketModel')).setProperties({
				shippingAddress1: merchantAddress.address1,
				shippingAddress2: merchantAddress.address2,
				shippingCity: merchantAddress.city,
				shippingState: merchantAddress.state,
				shippingZip: merchantAddress.zip
			});
		},

		setShippingAddressObserver: Ember.observer('chosenAddress', function () {
			this.setShippingAddress();
		}),
		setMerchantInfoObserver: Ember.observer('chosenAddress', function () {
			this.setMerchantInfo();
		}),
		setMerchantInfo: function setMerchantInfo() {
			if (!this.get('chosenAddress')) {
				return;
			}

			var ticketModel = this.get('ticketModel');
			var chosenAddress = this.get('chosenAddress').toLowerCase();

			this.setMerchantOfficeAndAttention(chosenAddress, ticketModel);
		},
		setMerchantOfficeAndAttention: function setMerchantOfficeAndAttention(address, modelName) {
			var model = this.get('model.' + modelName);

			var _get$getProperties2 = this.get('model.merchant').getProperties(['dbaName', 'isoOfficeName']),
			    dbaName = _get$getProperties2.dbaName,
			    isoOfficeName = _get$getProperties2.isoOfficeName;

			var chosenAddress = address.toLowerCase();

			if (chosenAddress.includes(ADDRESS_TYPES_NAMES[0]) || chosenAddress.includes(ADDRESS_TYPES_NAMES[1])) {
				model.setProperties({
					shippingTo: dbaName
				});
			}

			if (chosenAddress.includes(ADDRESS_TYPES_NAMES[2]) || chosenAddress.includes(ADDRESS_TYPES_NAMES[3])) {
				model.setProperties({
					shippingTo: isoOfficeName
				});
			}
		},
		setChosenAddress: function setChosenAddress(address) {
			var addressIndex = ADDRESS_TYPES_NAMES.indexOf(address);

			if (addressIndex >= 0) {
				this.set('chosenAddress', DEFAULT_DBA_ADDRESS_TYPES[addressIndex]);
			}
		}
	});
});