define('swap-system/models/rma-approval', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/mixins/models/commit-details'], function (exports, _emberData, _emberCpValidations, _commitDetails) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		rmaReason: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'Select a reason'
		}),
		rmaApprovalDetails: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
			min: 1,
			message: 'Select at least 1 equipment'
		})],
		file: (0, _emberCpValidations.validator)('presence', { presence: true }),
		rmaApprovalStatus: (0, _emberCpValidations.validator)('presence', { presence: true })
	});

	exports.default = Model.extend(validations, _commitDetails.default, {
		mid: attr('string'),
		dba: attr('string'),
		createdBy: attr('string'),
		createdAt: attr('utc-format'),
		approvalBy: attr('string'),
		approvalAt: attr('date'),
		denyReason: attr('string'),
		alertMerchant: attr('boolean', { allowNull: true }),
		alertVAR: attr('boolean', { allowNull: true }),
		file: belongsTo('merchant'),
		rma: belongsTo('rma'),
		rmaReason: belongsTo('rmaReason'),
		brand: belongsTo('brand'),
		orderType: belongsTo('orderType'),
		processorType: belongsTo('processorType'),
		rmaApprovalDetails: hasMany('rmaApprovalDetail'),
		rmaApprovalComments: hasMany('rmaApprovalComment'),
		rmaApprovalStatus: belongsTo('rmaApprovalStatus')
	});
});