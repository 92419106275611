define('swap-system/routes/login', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		routeAfterAuthentication: 'entry',
		routeIfAlreadyAuthenticated: 'entry',
		ajax: Ember.inject.service(),
		session: Ember.inject.service(),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			if (this.get('session.isAuthenticated')) {
				return this.transitionTo('/');
			}

			var err = transition.queryParams.error;
			if (err) {
				this.notifications.error(err);
			}

			return this.get('session').authenticate('authenticator:sso', transition.queryParams.token).then(function () {
				transition.abort();
				_this.transitionTo('/');
			}).catch(function () {
				_this.transitionTo('login');
			});
		},
		model: function model() {
			var callback = encodeURIComponent(window.location.origin + '/#/login');

			return Ember.RSVP.hash({
				authUrl: this.get('ajax').request('/api/v1/auth/url?callback=' + callback).then(function (_ref) {
					var url = _ref.url;
					return url;
				})
			});
		}
	});
});