define('swap-system/controllers/application', ['exports', 'swap-system/enums/userGroups'], function (exports, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var NO_STYLED_BODY_BLOCK_CURRENT_PATHES = ['packing-slip'];

	exports.default = Ember.Controller.extend({
		userGroups: _userGroups.default,
		session: Ember.inject.service('session'),
		ajax: Ember.inject.service(),
		brandImage: '/shift4-logo.svg',
		user: Ember.inject.service(),
		isNoStyledBodyBlock: Ember.computed('currentPath', function () {
			return NO_STYLED_BODY_BLOCK_CURRENT_PATHES.includes(this.currentPath);
		}),
		swapsPendingApproval: null,
		rmasPendingApproval: null,
		swapFlags: null,
		swapsAwaitingFulfillment: null,
		adminPanelProductList: null,
		ticking: false,
		feedbackComment: '',
		refreshCounterTimeout: 15000,
		refreshCounterId: null,
		menuItems: Ember.computed('user.userData.email', function () {
			var items = [{
				route: 'search',
				name: 'Create Swap',
				template: 'menu/create-swap'
			}, {
				route: 'swaps',
				name: 'Find Swaps',
				template: 'menu/find-swap'
			}, {
				route: 'approveList',
				name: 'Swaps Pending Approval',
				template: 'menu/swaps-pending-approval'
			}, {
				route: 'searchRma',
				name: 'Create RMA',
				template: 'menu/create-rma'
			}, {
				route: 'rmas',
				name: 'Find RMAs',
				template: 'menu/find-rma'
			}, {
				route: 'rmaApproveList',
				name: 'RMAs Pending Approval',
				template: 'menu/rmas-pending-approval'
			}];

			var isUserDataNotNull = !!this.get('user.userData');
			var isSwapAdmin = isUserDataNotNull ? this.get('user').belongsTo(_userGroups.default.swapAdmin) : false;

			if (isSwapAdmin) {
				items = items.concat([{
					template: 'menu/menu-group-line'
				}, {
					route: 'adminPanelShippingAddressList',
					name: 'Admin Panel - Shipping Address Mapping',
					template: 'menu/admin-panel-altered-shipping-address-list'
				}, {
					route: 'adminPanelProductList',
					name: 'Admin Panel - Products Mapping',
					template: 'menu/admin-panel-product-list'
				}]);
			}

			return items;
		}),
		getData: function getData(path) {
			return this.get('ajax').request('' + path);
		},
		refreshCounters: function refreshCounters() {
			if (this.get('session.isAuthenticated')) {
				this.getInfo('/api/v1/swapApprovals/countPending', 'swapsPendingApproval', 'Error while fetching pending swap approvals');
				this.getInfo('/api/v1/rmaApprovals/countPending', 'rmasPendingApproval', 'Error while fetching pending rma approvals');
				this.getInfo('/api/v1/swaps/countFlags', 'swapFlags', 'Error while fetching swap flags');
				this.getInfo('/api/v1/swaps/countAwaitingFulfillment', 'swapsAwaitingFulfillment', 'Error while fetching swaps awaiting fulfillment');
			}
		},
		getInfo: function getInfo(url, model, error) {
			var _this = this;

			this.getData(url).then(function (data) {
				_this.set(model, data.result);
			}).catch(function () {
				_this.notifications.error(error);
				_this.get('session').invalidate();
			});
		},
		init: function init() {
			var _this2 = this;

			this.refreshCounters();
			this.set('ticking', true);

			Ember.run(function () {
				setInterval(function () {
					_this2.refreshCounters();
				}, _this2.refreshCounterTimeout);
			});
		},

		actions: {
			updateClient: function updateClient() {
				if (this.get('session.isAuthenticated')) {
					this.get('session').invalidate();
				} else {
					window.location.reload(true);
				}
			},
			transitionToPage: function transitionToPage(route) {
				this.transitionToRoute(route);
			}
		}
	});
});