define('swap-system/mixins/controllers/confirmation-modal', ['exports', 'swap-system/utils/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		actions: {
			openConfirmationModal: function openConfirmationModal(transition) {
				this.set('transition', transition);
				this.send('showModal', 'modal-unsaved-changes');
			},
			leaveChangesUnsaved: function leaveChangesUnsaved() {
				var _this = this;

				// wait 400ms for modal animation to complete to prevent semantic-ui errors
				(0, _utils.waitForModalAnimation)(function () {
					var model = _this.get('model');
					var modelName = _this.get('ticketModel');
					model[modelName].rollback();
					model[modelName].get(modelName + 'Details').filterBy('hasDirtyAttributes').forEach(function (item) {
						return item.rollback();
					});
					_this.get('transition').retry();
				});
			}
		}
	});
});