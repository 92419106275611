define('swap-system/helpers/is-equal-day', ['exports', 'ember-date-components/helpers/is-equal-day'], function (exports, _isEqualDay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isEqualDay.default;
    }
  });
  Object.defineProperty(exports, 'isEqualDay', {
    enumerable: true,
    get: function () {
      return _isEqualDay.isEqualDay;
    }
  });
});