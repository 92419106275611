define('swap-system/models/rma-approval-detail', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/validations/base-ticket-validations'], function (exports, _emberData, _emberCpValidations, _baseTicketValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var rmaValidations = Ember.assign({
		billAmount: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			regex: /(^\d*\.?\d{1,2}$)|(^\d+\.\d{0,2}$)/
		}),
		sendReplacement: (0, _emberCpValidations.validator)('inclusion', {
			allowBlank: true,
			in: [true, false],
			message: '"Replace" must be either true or false'
		}),
		rmaApproval: (0, _emberCpValidations.validator)('belongs-to')
	}, _baseTicketValidations.default);

	var validations = (0, _emberCpValidations.buildValidations)(rmaValidations);

	exports.default = Model.extend(validations, {
		equipmentId: attr('string'),
		orderId: attr('string'),
		equipmentSource: attr('string'),
		modelName: attr('string'),
		productName: attr('string'),
		serialNumber: attr('string'),
		isSerialNeeded: attr('boolean'),
		billAmount: attr('string'),
		sendReplacement: attr('boolean', { defaultValue: true }),
		product: belongsTo('product', { inverse: 'rmaApprovalDetails' }),
		rmaApproval: belongsTo('rmaApproval'),
		swapRmaSubstitutionProduct: belongsTo('swapRmaSubstitutionProduct')
	});
});