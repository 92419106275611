define('swap-system/models/swap', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/mixins/models/commit-details', 'swap-system/mixins/models/swap/set-notifications', 'swap-system/validations/address-validations'], function (exports, _emberData, _emberCpValidations, _commitDetails, _setNotifications, _addressValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var swapValidations = Ember.assign({
		mid: (0, _emberCpValidations.validator)('presence', { presence: true }),
		dba: (0, _emberCpValidations.validator)('presence', { presence: true }),
		cancelledReason: (0, _emberCpValidations.validator)('length', {
			max: 255
		}),
		file: (0, _emberCpValidations.validator)('presence', { presence: true }),
		swapDetails: [(0, _emberCpValidations.validator)('has-many')],
		swapApproval: (0, _emberCpValidations.validator)('presence', { presence: true }),
		swapStatus: (0, _emberCpValidations.validator)('presence', { presence: true }),
		isVip: (0, _emberCpValidations.validator)('presence', { presence: true })
	}, _addressValidations.default);

	var validations = (0, _emberCpValidations.buildValidations)(swapValidations);

	exports.default = Model.extend(validations, _commitDetails.default, _setNotifications.default, {
		mid: attr('string'),
		dba: attr('string'),
		merchantAddresses: attr(),
		shippingAddress1: attr('string'),
		shippingAddress2: attr('string'),
		shippingCity: attr('string'),
		shippingState: attr('string'),
		shippingZip: attr('string'),
		shippingExtendedZip: attr('string'),
		shippingTo: attr('string'),
		shippingAttention: attr('string'),
		shippingMethod: attr('string'),
		shippedFrom: attr('string'),
		fulfilledBy: attr('string'),
		fulfilledAt: attr('date'),
		cancelledBy: attr('string'),
		cancelledAt: attr('date'),
		cancelledReason: attr('string'),
		createdBy: attr('string'),
		createdAt: attr('utc-format'),
		submittedBy: attr('string'),
		submittedAt: attr('utc-format'),
		flaggedNotificationError: attr('boolean'),
		orderShippedNotificationError: attr('boolean'),
		isVip: attr('boolean', { defaultValue: false }),
		file: belongsTo('merchant'),
		orderType: belongsTo('orderType'),
		processorType: belongsTo('processorType'),
		brand: belongsTo('brand'),
		swapDetails: hasMany('swapDetail', { async: false }),
		swapComments: hasMany('swapComment'),
		rma: belongsTo('rma'),
		swapApproval: belongsTo('swapApproval'),
		swapStatus: belongsTo('swapStatus'),
		isAutobuilderEnabled: attr('boolean')
	});
});