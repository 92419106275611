define('swap-system/routes/rma-approve-list', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/clear-store-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _clearStoreMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _clearStoreMixin.default, {
		requiredGroup: _userGroups.default.rmaUser,
		titleToken: function titleToken() {
			return 'RMA Approvals';
		},
		model: function model() {
			return Ember.RSVP.hash({
				rmaApprovals: this.store.query('rmaApproval', {}),
				rmaApprovalStatuses: this.store.findAll('rmaApprovalStatus'),
				rmaReasons: this.store.findAll('rmaReason'),
				brands: this.store.findAll('brand'),
				orderTypes: this.store.findAll('orderType'),
				processorTypes: this.store.findAll('processorType')
			});
		}
	});
});