define('swap-system/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var HEADERS = {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: 0
	};

	exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
		session: Ember.inject.service(),
		headers: Ember.computed(function () {
			var _get = this.get('session.data.authenticated'),
			    token = _get.token;

			if (Ember.isPresent(token)) {
				HEADERS['auth-token'] = token;
			}

			return HEADERS;
		}).volatile(),
		authorize: function authorize(xhr) {
			var _get2 = this.get('session.data.authenticated'),
			    email = _get2.email,
			    token = _get2.token;

			var authData = 'Token token="' + token + '", email="' + email + '"';
			xhr.setRequestHeader('Authorization', authData);
		},
		handleResponse: function handleResponse(status) {
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, this.get('session'))) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		},

		namespace: '/api/v1'
	});


	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});