define('swap-system/validations/base-ticket-validations', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		equipmentId: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Equipment ID" is required'
		}),
		equipmentSource: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Equipment Source" is required'
		}),
		productName: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Product Name" is required'
		}),
		isSerialNeeded: (0, _emberCpValidations.validator)('inclusion', {
			allowBlank: true,
			in: [true, false],
			message: '"Is Serial Needed" must be either true or false'
		})
	};
});