define('swap-system/components/line-breaks', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		value: null,
		lines: Ember.computed('value', function () {
			if (!this.get('value')) {
				return [];
			}

			var lines = this.get('value').split('\n');

			return lines.map(function (line, index) {
				return {
					content: line,
					isLast: index === lines.length - 1
				};
			});
		})
	});
});