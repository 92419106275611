define('swap-system/mixins/routes/clear-store-mixin', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var STORE_NAMES = ['brand', 'orderType', 'processorType', 'swapApprovalDetail', 'swapApprovalComment', 'swapDetail', 'equipment', 'rmaApprovalDetail', 'rmaApprovalComment', 'rmaDetail', 'swapApprovalStatus', 'rmaApprovalStatus', 'rmaStatus', 'swapStatus'];
	var MODEL_NAMES = ['rmaReason', 'swapApprovalStatus', 'rmaApprovalStatus', 'rmaStatus', 'swapStatus'];
	exports.default = Ember.Mixin.create({
		_unloadStoreRecords: function _unloadStoreRecords(items) {
			items.forEach(function (item) {
				item.unloadRecord();
				items.removeObject(item);
			});
		},
		_unloadModelRecords: function _unloadModelRecords(model, name) {
			if (model[name]) {
				model[name].unloadRecord();
			}
		},

		actions: {
			willTransition: function willTransition() {
				var _this = this;

				var model = this.modelFor(this.routeName);

				STORE_NAMES.forEach(function (x) {
					var items = _this.store.peekAll(x);
					_this._unloadStoreRecords(items);
				});
				MODEL_NAMES.forEach(function (x) {
					_this._unloadModelRecords(model, x);
				});
			}
		}
	});
});