define('swap-system/routes/swaps', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/clear-store-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _clearStoreMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _clearStoreMixin.default, {
		requiredGroup: _userGroups.default.swapUser,
		titleToken: function titleToken() {
			this._super.apply(this, arguments);

			return 'Find Swap';
		},

		model: function model(params) {
			var swapPageParams = this.get('controller.swapPageParams');

			var filter = {};

			if (swapPageParams) {
				filter = buildFilter(swapPageParams);

				this.get('controller').set('startPageNumber', swapPageParams.page);
			}

			return Ember.RSVP.hash({
				swaps: this.store.query('swap', {
					search: params.search,
					page: swapPageParams && swapPageParams.page || params.page,
					flag: params.flagged,
					isFulfilled: params.fulfilled,
					isCancelled: params.cancelled,
					sort: params.sort,
					direction: params.direction,
					limit: params.limit,
					pageSize: swapPageParams && swapPageParams.pageSize || 25,
					filter: filter
				}),
				swapStatuses: this.store.findAll('swapStatus'),
				brands: this.store.findAll('brand'),
				swapApprovals: this.store.findAll('swapApproval'),
				orderTypes: this.store.findAll('orderType'),
				processorTypes: this.store.findAll('processorType')
			});
		}
	});


	/**
  * Builds filter for swaps from saved params.
  * @param   {Object}        swapPageParams params received from server-table action displayDataChangedAction
  * @returns {Object}        returns filter for swaps request
  */
	function buildFilter(swapPageParams) {
		var columnFilters = swapPageParams.columnFilters;


		if (!columnFilters) {
			return {};
		}

		var brandId = columnFilters.brandId,
		    dba = columnFilters.dba,
		    id = columnFilters.id,
		    isVip = columnFilters.isVip,
		    mid = columnFilters.mid,
		    orderTypeId = columnFilters.orderTypeId,
		    submittedBy = columnFilters.submittedBy,
		    submittedAt = columnFilters.submittedAt,
		    swapStatusId = columnFilters.swapStatusId;


		return {
			brand: brandId,
			dba: dba,
			id: id,
			orderType: orderTypeId,
			swapStatus: swapStatusId,
			submittedBy: submittedBy,
			mid: mid,
			isVip: isVip,
			submittedAt: submittedAt && submittedAt.start ? submittedAt : undefined,
			swapApproval: columnFilters['swapApproval.id'],
			rma: columnFilters['rma.id']
		};
	}
});