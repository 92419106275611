define('swap-system/validations/admin-panel-address-validations', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		shippingAddress1: [(0, _emberCpValidations.validator)('length', {
			max: 255,
			message: '"Shipping address 1" length cannot exceed 255 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Shipping address 1" is required'
		})],
		shippingAddress2: (0, _emberCpValidations.validator)('length', {
			max: 255,
			message: '"Shipping address 2" length cannot exceed 255 characters'
		}),
		shippingCity: [(0, _emberCpValidations.validator)('length', {
			max: 255,
			message: '"Shipping city" length cannot exceed 255 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Shipping city" is required'
		})],
		shippingState: [(0, _emberCpValidations.validator)('length', {
			max: 255,
			message: '"Shipping state" length cannot exceed 255 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Shipping state" is required'
		})],
		shippingZip: [(0, _emberCpValidations.validator)('length', {
			max: 5,
			message: '"Shipping ZIP" length cannot exceed 5 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Shipping ZIP" is required'
		})],
		shippingAttention: (0, _emberCpValidations.validator)('length', {
			max: 255,
			allowBlank: true,
			message: '"Shipping attention" length cannot exceed 255 characters'
		})
	};
});