define('swap-system/routes/deb', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, {
		requiredGroup: _userGroups.default.rmaUser,
		titleToken: function titleToken() {
			return 'Find RMA';
		},
		model: function model() {
			return this.store.query('rma', { deb: true });
		}
	});
});