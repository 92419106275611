define('swap-system/enums/userGroups', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		swapAdmin: 'sg-swap-admin@shift4.com',
		swapDeployment: 'sg-swap-deployment@shift4.com',
		swapSystemBetaUser: 'sg-swap-beta@shift4.com',
		swapUser: 'sg-swap-user@shift4.com',
		swapManualAdd: 'sg-swap-manualadd@shift4.com',
		rmaAdmin: 'sg-rma-admin@shift4.com',
		rmaApprovalView: 'sg-rma-approvalview@shift4.com',
		rmaDeployment: 'sg-rma-deployment@shift4.com',
		rmaManualAdd: 'sg-rma-manualadd@shift4.com',
		rmaPriceUpdate: 'sg-rma-priceupdate@shift4.com',
		rmaUser: 'sg-rma-user@shift4.com',
		rmaReturnNotNeeded: 'sg-rma-returnnotneeded@shift4.com'
	};
});