define("swap-system/enums/rmaReasons", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		swap: 1,
		rmaOnly: 2,
		posUpgrade: 3,
		posSoftwareChange: 4,
		posInvoice: 5,
		accountClosureCancellation: 6
	};
});