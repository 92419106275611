define('swap-system/models/altered-shipping-address', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/validations/admin-panel-address-validations'], function (exports, _emberData, _emberCpValidations, _adminPanelAddressValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var officeParamsValidations = (0, _emberCpValidations.buildValidations)({
		officeId: (0, _emberCpValidations.validator)('presence', { presence: true }),
		officeName: (0, _emberCpValidations.validator)('presence', { presence: true })
	});

	var addressValidations = (0, _emberCpValidations.buildValidations)(_adminPanelAddressValidations.default, {
		disabled: Ember.computed('model.addressValidationDisabled', function () {
			return this.get('model.addressValidationDisabled');
		})
	});

	exports.default = Model.extend(officeParamsValidations, addressValidations, {
		officeId: attr('string'),
		officeName: attr('string'),
		officeAddresses: attr(''),
		isLocked: attr('boolean'),
		defaultShippingAddress: belongsTo('defaultShippingAddress'),
		shippingAttention: attr('string'),
		shippingAddress1: attr('string'),
		shippingAddress2: attr('string'),
		shippingCity: attr('string'),
		shippingState: attr('string'),
		shippingZip: attr('string'),
		partnerLevel: attr('string')
	});
});