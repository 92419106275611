define('swap-system/controllers/swaps', ['exports', 'swap-system/mixins/controllers/ticket-list', 'swap-system/mixins/controllers/server-table-common-properties'], function (exports, _ticketList, _serverTableCommonProperties) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_ticketList.default, _serverTableCommonProperties.default, {
		routeName: 'swap',
		filterPropertyName: 'swapStatus',
		modelName: 'swapStatuses',
		templateName: 'ember-common-table/swaps',
		submittedAtPropertyName: 'submittedAt',
		submittedByPropertyName: 'submittedBy',
		useFilteringByColumns: true,
		focusGlobalFilter: true,
		sendDisplayDataChangedAction: true,
		startPageNumber: 1,
		actions: {
			displayDataChangedAction: function displayDataChangedAction(data) {
				var swapPageParams = {
					columnFilters: data.columnFilters,
					pageSize: data.pageSize,
					page: data.currentPageNumber
				};

				this.set('swapPageParams', swapPageParams);
			}
		}
	});
});