define('swap-system/components/shipping-address-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isLockedClone: function () {
			return this.get('isLocked');
		}.property('isLocked'),
		onIsLockerBlockedChange: Ember.observer('isLockerBlocked', function () {
			var isLockerBlocked = this.get('isLockerBlocked');

			if (isLockerBlocked) {
				this.set('isLockedClone', false);
				this.sendAction('changeIsLocked', false);
			}
		}),
		actions: {
			handleDefaultOfficeAddressSelect: function handleDefaultOfficeAddressSelect(data) {
				this.sendAction('changeDefaultShippingAddress', data);
			},
			handleIsLockedChange: function handleIsLockedChange(value) {
				this.sendAction('changeIsLocked', value);
			}
		}
	});
});