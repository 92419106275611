define('swap-system/helpers/user-belongs-to', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		user: Ember.inject.service(),
		compute: function compute(params) {
			return this.get('user').belongsTo(params[0]);
		}
	});
});