define('swap-system/helpers/is-equal-year', ['exports', 'ember-date-components/helpers/is-equal-year'], function (exports, _isEqualYear) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isEqualYear.default;
    }
  });
  Object.defineProperty(exports, 'isEqualYear', {
    enumerable: true,
    get: function () {
      return _isEqualYear.isEqualYear;
    }
  });
});