define('swap-system/models/rma-fee', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var validations = (0, _emberCpValidations.buildValidations)({
		feeName: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Fee Name" is required'
		}),
		dueDate: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Due Date" is required'
		}),
		billAmount: (0, _emberCpValidations.validator)('format', {
			allowBlank: false,
			regex: /(^\d*\.?\d{1,2}$)|(^\d+\.\d{0,2}$)/,
			message: 'Bill amount must be a valid price and not empty'
		}),
		rma: [(0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
	});

	exports.default = Model.extend(validations, {
		feeName: attr('string'),
		billAmount: attr('string', { defaultValue: '0.00' }),
		dueDate: attr('date'),
		isApplicable: attr('boolean'),
		isBilled: attr('boolean'),
		rma: belongsTo('rma')
	});
});