define('swap-system/controllers/search', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		search: '',
		searching: false,
		didSearch: false,
		actions: {
			clickSearch: function clickSearch() {
				Ember.$('#search').click();
			},
			refreshModel: function refreshModel() {
				var _this = this;

				if (this.get('search')) {
					return this.store.queryRecord('merchant', { mid: this.get('search') }).then(function (data) {
						if (data.get('status') === 330 || data.get('status') === 350 || data.get('status') >= 600) {
							_this.transitionToRoute('create', data.get('mid'));
						} else {
							Ember.$('.ui.modal.merchantNotFound').modal('show');
						}
					}).catch(function () {
						Ember.$('.ui.modal.merchantNotFound').modal('show');
					});
				}
			}
		}
	});
});