define('swap-system/validations/address-validations', ['exports', 'ember-cp-validations', 'swap-system/validations/admin-panel-address-validations'], function (exports, _emberCpValidations, _adminPanelAddressValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var addressValidations = Ember.assign({
		shippingExtendedZip: [(0, _emberCpValidations.validator)('number', {
			integer: true,
			positive: true,
			allowString: true,
			allowBlank: true,
			message: '"Shipping extended ZIP" is not a valid format'
		}), (0, _emberCpValidations.validator)('length', {
			max: 4,
			allowBlank: true,
			message: '"Shipping extended ZIP" length cannot exceed 4 characters'
		})],
		shippingTo: [(0, _emberCpValidations.validator)('length', {
			max: 255,
			message: '"Company/person" length cannot exceed 255 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"Company/person" is required'
		})],
		shippingMethod: (0, _emberCpValidations.validator)('length', {
			allowBlank: false,
			in: ['Ground', '2nd Day', 'Next Day', 'Saturday', 'Saturday 10AM', 'On Site']
		})
	}, _adminPanelAddressValidations.default);

	exports.default = addressValidations;
});