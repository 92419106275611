define('swap-system/models/admin-panel-product', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		productName: (0, _emberCpValidations.validator)('presence', { presence: true }),
		modelNo: (0, _emberCpValidations.validator)('presence', { presence: true }),
		subProducts: (0, _emberCpValidations.validator)('has-many'),
		availableSubstitutions: (0, _emberCpValidations.validator)('has-many')
	});

	exports.default = Model.extend(validations, {
		productName: attr('string'),
		modelNo: attr('string'),
		subProducts: hasMany('adminPanelProductSubproduct', { async: false }),
		availableSubstitutions: hasMany('adminPanelProductSubstitutionProduct', { async: false }),
		isAvailable: attr('boolean'),
		isAutoApprove: attr('boolean')
	});
});