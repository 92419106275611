define('swap-system/models/rma-approval-comment', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var validations = (0, _emberCpValidations.buildValidations)({
		comment: [(0, _emberCpValidations.validator)('length', {
			max: 10000,
			message: '"RMA approval comment" length cannot exceed 10,000 characters'
		}), (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: '"RMA approval" is required'
		})],
		rmaApproval: [(0, _emberCpValidations.validator)('presence', true)]
	});

	exports.default = Model.extend(validations, {
		comment: attr('string'),
		createdBy: attr('string'),
		createdAt: attr('date'),
		rmaApproval: belongsTo('rmaApproval')
	});
});