define('swap-system/models/condition-type', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend({
		displayName: attr('string')
	});
});