define('swap-system/components/ht-loader', ['exports', 'ember-common/components/ht-loader'], function (exports, _htLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _htLoader.default;
    }
  });
});