define("swap-system/enums/boardingStatuses", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		boardingNotNeeded: 1,
		pending: 2,
		boarded: 3,
		boardingFailed: 4
	};
});