define('swap-system/router', ['exports', 'swap-system/config/environment', 'ember-common/utils/router-setup'], function (exports, _environment, _routerSetup) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Router = Ember.Router.extend({
		location: _environment.default.locationType,
		rootURL: _environment.default.rootURL
	});

	Router.map(function () {
		(0, _routerSetup.default)(this);
		this.route('login', { path: '/login' });
		this.route('entry', { path: '/' });
		this.route('swap', { path: '/swap/record/:id' });
		this.route('swaps', { path: 'swap/find' });
		this.route('search', { path: '/swap/create' });
		this.route('create', { path: '/swap/create/:id' });
		this.route('packing-slip', { path: '/swap/packing-slip/:id' });
		this.route('approveList', { path: '/swap/approve/pending' });
		this.route('approve', { path: '/swap/approve/record/:id' });
		this.route('deb', { path: '/deb' });
		this.route('rmas', { path: '/rma/find' });
		this.route('rma', { path: '/rma/record/:id' });
		this.route('createRma', { path: '/rma/create/:id' });
		this.route('searchRma', { path: '/rma/create' });
		this.route('rmaApproveList', { path: '/rma/approve/pending' });
		this.route('rmaApprove', { path: '/rma/approve/record/:id' });
		this.route('adminPanelProduct', { path: '/adminPanel/products/:id' });
		this.route('adminPanelProductList', { path: '/adminPanel/products' });
		this.route('adminPanelCreateAlteredShippingAddress', { path: '/adminPanel/shippingAddresses/create' });
		this.route('adminPanelAlteredShippingAddress', { path: '/adminPanel/shippingAddresses/:officeId' });
		this.route('adminPanelAlteredShippingAddressList', { path: '/adminPanel/shippingAddresses' });
	});

	exports.default = Router;
});