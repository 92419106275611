define("swap-system/enums/orderTypes", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		pos: 1,
		accessory: 2,
		terminal: 3,
		mixed: 4
	};
});