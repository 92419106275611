define('swap-system/routes/admin-panel-altered-shipping-address', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/clear-store-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _clearStoreMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _clearStoreMixin.default, {
		requiredGroups: _userGroups.default.swapAdmin,
		model: function model(params) {
			return Ember.RSVP.hash({
				alteredShippingAddress: this.store.queryRecord('alteredShippingAddress', { officeId: params.officeId }),
				defaultShippingAddresses: this.store.findAll('defaultShippingAddress', {})
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.resetForm();
		}
	});
});