define('swap-system/mixins/controllers/calculate-order-type', ['exports', 'swap-system/enums/orderTypes'], function (exports, _orderTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var CURVEPAY_MID_LENGTH = 16;

	exports.default = Ember.Mixin.create({
		approvalModel: '',
		calculateOrderType: function calculateOrderType(model) {
			if (this.isCurvepayMerchant(model)) {
				return this.setOrderType(model, _orderTypes.default.terminal);
			}

			var details = this.getApprovalDetailsForReplacement();
			var productTypes = details.reduce(function (result, detail) {
				var productType = detail.get('product.productType') || '';

				switch (productType.toUpperCase()) {
					case 'TERMINAL':
					case 'SERVICE':
						result.pos = true;
						break;
					case 'CCTERMINAL':
						result.ccterminal = true;
						break;
					default:
						result.accessory = true;
				}

				return result;
			}, {});

			var orderType = this.getOrderType(productTypes);
			this.setOrderType(model, orderType);
		},
		getApprovalDetailsForReplacement: function getApprovalDetailsForReplacement() {
			var approvalModel = this.get('approvalModel');
			return (this.get('model.' + approvalModel + '.' + approvalModel + 'Details') || []).filter(function (x) {
				return x.get('sendReplacement');
			});
		},
		isCurvepayMerchant: function isCurvepayMerchant(model) {
			return model.get('mid').length === CURVEPAY_MID_LENGTH;
		},
		getOrderType: function getOrderType(productTypes) {
			if (productTypes.pos) {
				return productTypes.ccterminal ? _orderTypes.default.mixed : _orderTypes.default.pos;
			}

			return productTypes.ccterminal ? _orderTypes.default.terminal : _orderTypes.default.accessory;
		},
		setOrderType: function setOrderType(model, orderType) {
			model.set('orderType', this.store.peekRecord('orderType', orderType));
		}
	});
});