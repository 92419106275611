define('swap-system/helpers/get-alias', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var map = {
		swaps: {
			swapStatusId: 'Status',
			shippingAddress1: 'Address',
			shippingAddress2: 'Address 2',
			shippingCity: 'City',
			shippingState: 'State',
			shippingZip: 'Zip',
			shippingExtendedZip: 'Extended zip',
			shippingTo: 'Company/person',
			shippingAttention: 'Attention',
			shippingMethod: 'Method',
			cancelledBy: 'Cancelled by',
			fulfilledBy: 'Fulfilled by',
			returnedBy: 'Returned by',
			approvalBy: 'Approval by',
			processorTypeId: 'Processor',
			cancelledAt: 'Cancelled at',
			fulfilledAt: 'Fulfilled at',
			returnedAt: 'Returned at',
			approvalAt: 'Approval at'
		},
		swapDetails: {
			terminalNumber: 'Terminal number',
			ipAddress: 'IP address'
		},
		swapApprovals: {
			swapApprovalStatusId: 'Status',
			approvalBy: 'Approval by',
			alertMerchant: 'Alert merchant',
			alertVAR: 'Alert VAR',
			processorTypeId: 'Processor'
		},
		rmas: {
			rmaStatusId: 'Status',
			returnTrackingNumber: 'Tracking number',
			cancelledBy: 'Cancelled by',
			fulfilledBy: 'Fulfilled by',
			returnedBy: 'Returned by',
			approvalBy: 'Approval by',
			processorTypeId: 'Processor',
			dueDate: 'Due date',
			approvalAt: 'Approval at',
			cancelledAt: 'Cancelled at',
			returnedAt: 'Returned at'
		},
		rmaDetails: {
			oldSerialNumber: 'Serial number',
			isCancelled: 'Cancelled',
			isReturned: 'Check In Item',
			returnedAt: 'Returned at',
			returnedBy: 'Returned By',
			isBilled: 'Bill Item',
			dueOn: 'Due date',
			billAmount: 'Bill amount',
			billedAt: 'Billed at',
			billingEnabled: 'Automatic billing',
			isReturnNotNeeded: 'Return Not Needed'
		},
		rmaApprovals: {
			rmaApprovalStatusId: 'Status',
			approvalBy: 'Approval by',
			alertMerchant: 'Alert merchant',
			alertVAR: 'Alert VAR',
			processorTypeId: 'Processor',
			approvalAt: 'Approval at'
		}
	};

	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			var model = params[0];
			var attribute = params[1];
			if (map[model] && map[model][attribute]) {
				return map[model][attribute];
			}
			return attribute;
		}
	});
});