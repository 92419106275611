define('swap-system/routes/admin-panel-product-list', ['exports', 'swap-system/mixins/routes/authenticated-route-mixin', 'swap-system/mixins/routes/required-group-mixin', 'swap-system/mixins/routes/clear-store-mixin', 'swap-system/enums/userGroups'], function (exports, _authenticatedRouteMixin, _requiredGroupMixin, _clearStoreMixin, _userGroups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_IF_AVAILABLE = 'true';

	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _requiredGroupMixin.default, _clearStoreMixin.default, {
		requiredGroup: _userGroups.default.swapAdmin,
		titleToken: function titleToken() {
			return 'Admin Panel - Products Mapping';
		},
		model: function model() {
			return Ember.RSVP.hash({
				adminPanelProducts: this.store.query('adminPanelProduct', { filter: { isAvailable: DEFAULT_IF_AVAILABLE } })
			});
		}
	});
});