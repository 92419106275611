define('swap-system/serializers/admin-panel-product-substitution-product', ['exports', 'ember-data', 'swap-system/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			substitutionSubproducts: { embedded: 'always' }
		}
	});
});