define('swap-system/helpers/is-log-date', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DATE_FIELDS = ['createdAt', 'approvalAt', 'cancelledAt', 'submittedAt', 'dueDate', 'dueOn', 'fulfilledAt', 'returnedAt', 'returnNotNeededAt', 'billedAt'];

	exports.default = Ember.Helper.extend({
		compute: function compute(params) {
			return DATE_FIELDS.includes(params[0].get('column'));
		}
	});
});