define("swap-system/enums/swapApprovalStatuses", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		pending: 1,
		denied: 2,
		approved: 3
	};
});