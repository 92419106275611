define('swap-system/components/select-panel', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: 'three wide column',
		title: 'No title',
		placeholder: 'No placeholder',
		labelClassName: '',
		disabled: null,
		options: [],
		option: 'displayName',
		selected: null,
		onchange: function onchange() {}
	});
});