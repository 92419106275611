define('swap-system/services/ajax', ['exports', 'ember-ajax/services/ajax', 'swap-system/config/environment'], function (exports, _ajax, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ajax.default.extend({
		host: _environment.default.host,
		session: Ember.inject.service(),
		contentType: 'application/json',
		headers: Ember.computed('session.data', 'session.isAuthenticated', function () {
			return this.get('session.isAuthenticated') ? { 'auth-token': this.get('session.data.authenticated').token } : {};
		}),
		handleResponse: function handleResponse(status) {
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, this.get('session'))) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		}
	});


	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});