define('swap-system/models/substitution-subproduct', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo,
	    attr = _emberData.default.attr;
	exports.default = Model.extend({
		substitutionProductId: attr('string'),
		product: belongsTo('product', { inverse: null })
	});
});