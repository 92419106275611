define('swap-system/models/swap-approval-detail', ['exports', 'ember-data', 'ember-cp-validations', 'swap-system/validations/base-ticket-validations'], function (exports, _emberData, _emberCpValidations, _baseTicketValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var swapValidations = Ember.assign({
		returnEquipment: (0, _emberCpValidations.validator)('inclusion', {
			allowBlank: true,
			in: [true, false],
			message: '"Return Equipment" must be either true or false'
		}),
		billAmount: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			regex: /(^\d*\.?\d{1,2}$)|(^\d+\.\d{0,2}$)/
		}),
		ipAddress: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			regex: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
			message: '"IP Address" must be a valid IPv4 format'
		}),
		sendReplacement: (0, _emberCpValidations.validator)('inclusion', {
			allowBlank: true,
			in: [true, false],
			message: '"Replace" must be either true or false'
		}),
		swapApproval: (0, _emberCpValidations.validator)('belongs-to'),
		swapRmaSubstitutionProduct: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'You should select a substitution product for each equipment'
		})
	}, _baseTicketValidations.default);

	var validations = (0, _emberCpValidations.buildValidations)(swapValidations);

	exports.default = Model.extend(validations, {
		equipmentId: attr(''),
		orderId: attr('string'),
		equipmentSource: attr(''),
		modelName: attr(''),
		productName: attr(''),
		serialNumber: attr(''),
		isSerialNeeded: attr(''),
		returnEquipment: attr(''),
		billAmount: attr('string'),
		terminalNumber: attr(''),
		ipAddress: attr('string'),
		sendReplacement: attr('boolean', { defaultValue: true }),
		product: belongsTo('product', { inverse: 'swapApprovalDetails' }),
		swapApproval: belongsTo('swapApproval'),
		swapRmaSubstitutionProduct: belongsTo('swapRmaSubstitutionProduct')
	});
});